






























import { VariableSheet } from "@/types/Sheets";
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component
export default class PickerItemsCard extends Vue {
  @Prop() type!: "Sheet" | "Template";
  @Prop() groupItem!: VariableSheet;

  mounted() {
    /* console.log(this.groupItem) */
  }

  colorCode(s: string) {
    return "#" + Buffer.from(s).toString("hex").substring(0, 6);
  }

  isActive(item: any) {
    if (this.$route.params.id == item.id) return "#43a047";
    return "";
  }

  to(item: any) {
    return { path: `/${this.type.toLowerCase()}s/${item.id}` };
  }
}
