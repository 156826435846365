import { RootState } from "@/types/RootState";
import { VariableSheet } from "@/types/Sheets"
import { collection, doc, updateDoc } from "firebase/firestore";
import { query, where, getDocs, addDoc, getDocsFromServer } from "firebase/firestore";
import { ActionContext } from "vuex";

type DocumentModuleState = {
}

const documentsModule = {
    state: (): DocumentModuleState => ({
    }),
    getters: {
    },
    actions: {
        createDocument(
            context: ActionContext<RootState, RootState>,
            payload: {
                collection: string;
                dataObj: any;
            }
        ) {
            return addDoc(collection(context.rootState.firestore, payload.collection), payload.dataObj);
        },
        updateDocument(
            context: ActionContext<RootState, RootState>,
            payload: {
                collection: string;
                id: string;
                dataObj: any
            }
        ) {
            const docRef = doc(context.rootState.firestore, payload.collection, payload.id)
            return updateDoc(docRef, payload.dataObj);
        },
    },
    mutations: {
    },
}

export { documentsModule }