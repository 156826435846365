var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',[_c('v-card',{attrs:{"outlined":"","justify":""}},[_c('v-card-title',{staticClass:"text-h4"},[_vm._v(_vm._s(_vm.category.toUpperCase()))]),_c('v-checkbox',{staticClass:"ml-4",attrs:{"label":"Exclude Cell From Template"},on:{"change":_vm.validateSetDeviation},model:{value:(_vm.form.excludeCell),callback:function ($$v) {_vm.$set(_vm.form, "excludeCell", $$v)},expression:"form.excludeCell"}}),_c('v-checkbox',{staticClass:"ml-4",attrs:{"label":"Flip Colors"},on:{"change":_vm.validateSetDeviation},model:{value:(_vm.form.flipColors),callback:function ($$v) {_vm.$set(_vm.form, "flipColors", $$v)},expression:"form.flipColors"}}),_c('v-checkbox',{staticClass:"ml-4",attrs:{"label":"No Colors"},on:{"change":_vm.validateSetDeviation},model:{value:(_vm.form.noColors),callback:function ($$v) {_vm.$set(_vm.form, "noColors", $$v)},expression:"form.noColors"}}),_c('v-text-field',{staticClass:"px-4",attrs:{"placeholder":"Stat Range Comment","hint":"Stat Range Comment","outlined":""},on:{"input":_vm.validateSetDeviation},model:{value:(_vm.form.comment),callback:function ($$v) {_vm.$set(_vm.form, "comment", $$v)},expression:"form.comment"}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!(_vm.form.excludeCell || _vm.form.noColors)),expression:"!(form.excludeCell || form.noColors)"}]},[_c('v-row',{staticClass:"mb-6 mx-12",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"offset-sm":"6","sm":"2"}},[_c('v-avatar',{attrs:{"color":this.form.flipColors
                ? _vm.sheetColors.severeBottom
                : _vm.sheetColors.severeTop,"size":"56"}})],1)],1),_c('v-row',{staticClass:"my-n6 mx-12",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"sm":"6"}},[_c('v-text-field',{attrs:{"placeholder":"Medium Top Threshold","hint":"Medium Top Threshold","outlined":"","error-messages":_vm.mediumTopThresholdErrors},on:{"input":function($event){return _vm.validateSetDeviation()},"blur":function($event){return _vm.$v.form.$touch()}},model:{value:(_vm.form.mediumTopThreshold),callback:function ($$v) {_vm.$set(_vm.form, "mediumTopThreshold", $$v)},expression:"form.mediumTopThreshold"}})],1),_c('v-col',{attrs:{"sm":"2"}},[_c('v-avatar',{attrs:{"color":this.form.flipColors
                ? _vm.sheetColors.mediumBottom
                : _vm.sheetColors.mediumTop,"size":"56"}})],1)],1),_c('v-row',{staticClass:"my-n6 mx-12",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"sm":"6"}},[_c('v-text-field',{attrs:{"placeholder":"Slight Top Threshold","hint":"Slight Top Threshold","outlined":"","error-messages":_vm.slightTopThresholdErrors},on:{"input":function($event){return _vm.validateSetDeviation()},"blur":function($event){return _vm.$v.form.$touch()}},model:{value:(_vm.form.slightTopThreshold),callback:function ($$v) {_vm.$set(_vm.form, "slightTopThreshold", $$v)},expression:"form.slightTopThreshold"}})],1),_c('v-col',{attrs:{"sm":"2"}},[_c('v-avatar',{attrs:{"color":this.form.flipColors
                ? _vm.sheetColors.slightBottom
                : _vm.sheetColors.slightTop,"size":"56"}})],1)],1),_c('v-row',{staticClass:"my-n6 mx-12",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"sm":"6"}},[_c('v-text-field',{attrs:{"placeholder":"Inner Top Threshold","hint":"Inner Top Threshold","outlined":"","error-messages":_vm.innerTopThresholdErrors},on:{"input":function($event){return _vm.validateSetDeviation()},"blur":function($event){return _vm.$v.form.$touch()}},model:{value:(_vm.form.innerTopThreshold),callback:function ($$v) {_vm.$set(_vm.form, "innerTopThreshold", $$v)},expression:"form.innerTopThreshold"}})],1),_c('v-col',{attrs:{"sm":"2"}},[_c('v-avatar',{attrs:{"color":this.form.flipColors
                ? _vm.sheetColors.innerBottom
                : _vm.sheetColors.innerTop,"size":"56"}})],1)],1),_c('v-row',{staticClass:"my-n6 mx-12",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"sm":"6"}},[_c('v-text-field',{attrs:{"placeholder":"Inner Bottom Threshold","hint":"Inner Bottom Threshold","outlined":"","error-messages":_vm.innerBottomThresholdErrors},on:{"input":function($event){return _vm.validateSetDeviation()},"blur":function($event){return _vm.$v.form.$touch()}},model:{value:(_vm.form.innerBottomThreshold),callback:function ($$v) {_vm.$set(_vm.form, "innerBottomThreshold", $$v)},expression:"form.innerBottomThreshold"}})],1),_c('v-col',{attrs:{"sm":"2"}},[_c('v-avatar',{attrs:{"color":this.form.flipColors
                ? _vm.sheetColors.innerTop
                : _vm.sheetColors.innerBottom,"size":"56"}})],1)],1),_c('v-row',{staticClass:"my-n6 mx-12",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"sm":"6"}},[_c('v-text-field',{attrs:{"placeholder":"Slight Bottom Threshold","hint":"Slight Bottom Threshold","outlined":"","error-messages":_vm.slightBottomThresholdErrors},on:{"input":function($event){return _vm.validateSetDeviation()},"blur":function($event){return _vm.$v.form.$touch()}},model:{value:(_vm.form.slightBottomThreshold),callback:function ($$v) {_vm.$set(_vm.form, "slightBottomThreshold", $$v)},expression:"form.slightBottomThreshold"}})],1),_c('v-col',{attrs:{"sm":"2"}},[_c('v-avatar',{attrs:{"color":this.form.flipColors
                ? _vm.sheetColors.slightTop
                : _vm.sheetColors.slightBottom,"size":"56"}})],1)],1),_c('v-row',{staticClass:"my-n6 mx-12",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"sm":"6"}},[_c('v-text-field',{attrs:{"placeholder":"Medium Bottom Threshold","hint":"Medium Bottom Threshold","outlined":"","error-messages":_vm.mediumBottomThresholdErrors},on:{"input":function($event){return _vm.validateSetDeviation()},"blur":function($event){return _vm.$v.form.$touch()}},model:{value:(_vm.form.mediumBottomThreshold),callback:function ($$v) {_vm.$set(_vm.form, "mediumBottomThreshold", $$v)},expression:"form.mediumBottomThreshold"}})],1),_c('v-col',{attrs:{"sm":"2"}},[_c('v-avatar',{attrs:{"color":this.form.flipColors
                ? _vm.sheetColors.mediumTop
                : _vm.sheetColors.mediumBottom,"size":"56"}})],1)],1),_c('v-row',{staticClass:"mt-n8 pb-8 mx-12",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"offset-sm":"6","sm":"2"}},[_c('v-avatar',{attrs:{"color":this.form.flipColors
                ? _vm.sheetColors.severeTop
                : _vm.sheetColors.severeBottom,"size":"56"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }