import Vue from "vue";
import VueScreen from "vue-screen";
import App from "./App.vue";
import VueRouter from "vue-router";
import Vuelidate from "vuelidate";
import dotenv from "dotenv";
import "reflect-metadata";
import store from "./store/index";
import vuetify from "./plugins/vuetify";
import VueQuill from "vue-quill";
import 'handsontable/dist/handsontable.full.css';
import router from "./router";
import { getAuth, onAuthStateChanged } from "firebase/auth";

dotenv.config();

Vue.use(VueQuill);
Vue.use(VueScreen);
Vue.use(VueRouter);
Vue.use(Vuelidate);

Vue.config.productionTip = false;

const app = new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
})
app.$mount("#app");

