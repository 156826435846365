




















































































// eslint-disable-next-line no-unused-vars
import { VariableSheet } from "@/types/Sheets";
import { Component, Prop, Vue } from "vue-property-decorator";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

@Component({
  mixins: [validationMixin],
  validations: {
    form: {
      columnKey: {
        required,
      },
      position: {
        required,
      },
    },
  },
})
export default class CreateMicroColumns extends Vue {
  @Prop() template!: VariableSheet;
  @Prop() categories!: string[];
  @Prop() positions!: string[];

  form = {
    columnKey: null,
    position: null,
  };

  get columnKeyErrors() {
    const errors: any[] = [];
    if (!this.$v.form.columnKey!.$dirty) return errors;
    !this.$v.form.columnKey!.required && errors.push("Column Key is required!");
    return errors;
  }

  get positionErrors() {
    const errors: any[] = [];
    if (!this.$v.form.position!.$dirty) return errors;
    !this.$v.form.position!.required && errors.push("Position is required!");
    return errors;
  }

  validateCreateColumn() {
    /* Set all elements to "touched"
        Trigger display of validation messages  */
    this.$v.form.columnKey!.$touch();

    if (!this.$v.form.columnKey!.$invalid) {
      this.createColumn(this.form.columnKey!);
      this.clearForm();
    }
  }

  validateCreatePosition() {
    this.$store.commit("setLoading", true);

    /* Set all elements to "touched"
        Trigger display of validation messages  */
    this.$v.form.position!.$touch();

    if (!this.$v.form.position!.$invalid) {
      this.createPosition(this.form.position!);
      this.clearForm();
    }
  }

  async createColumn(category: string) {
    const rows = this.template.rows.map((r: any) => {
      return { ...r, [category]: 1 };
    });

    const thresholds = this.template.thresholds.map((t: any) => {
      t.thresholds.push({
        category: category,
        inner_top: 4,
        inner_bottom: 3,
        slight_top: 5,
        slight_bottom: 2,
        medium_top: 6,
        medium_bottom: 1,
        excludeCell: false,
      });
      return {
        ...t,
        thresholds: t.thresholds,
      };
    });

    await this.$store.dispatch("persistTemplate", {
      ...this.template,
      thresholds,
      rows,
    });
    this.$store.commit("setLoading", false);
  }

  async createPosition(position: string) {
    this.$store.commit("setLoading", true);

    let categoryValues: any = {};
    this.categories.forEach((c: any) => (categoryValues[c] = 1));

    const rows = this.template.rows.concat({
      ...categoryValues,
      position: position,
    });

    const thresholdValues = this.categories.map((c: any) => {
      return {
        category: c,
        inner_top: 4,
        inner_bottom: 3,
        slight_top: 5,
        slight_bottom: 2,
        medium_top: 6,
        medium_bottom: 1,
      };
    });

    const thresholds = [
      ...this.template.thresholds,
      { position: position, thresholds: thresholdValues },
    ];

    await this.$store.dispatch("persistTemplate", {
      ...this.template,
      thresholds,
      rows,
    });
    this.$store.commit("setLoading", false);
  }

  clearForm() {
    this.$v.$reset();
    this.form.columnKey = null;
    this.form.position = null;
  }
}
