






























import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import PickerToolbar from "@/components/Shared/Picker/PickerToolbar.vue";
import PickerItems from "@/components/Shared/Picker/PickerItems.vue";
import PickerDialog from "@/components/Shared/Picker/PickerDialog.vue";
import CSVImportDialog from "@/components/Sheets/CSVImportDialog.vue";
import DivideTemplateDialog from "@/components/Templates/ContextMenu/Dialog/DivideTemplateDialog.vue";
import _ from "lodash-es";

@Component({
  components: {
    PickerToolbar,
    PickerItems,
    PickerDialog,
    CSVImportDialog,
    DivideTemplateDialog,
  },
})
export default class Picker extends Vue {
  @Prop() type!: "Template" | "Sheet";

  showDialog = false;
  showCSVDialog = false;
  showDivideDialog = false;

  chunkedSheetsOrTemplates(sheetType: "positional" | "preflop") {
    if (this.type == "Sheet") {
      const sheets = _.chunk(this.$store.getters.getSheetsByType(sheetType), 4);
      const sortedSheets = sheets.map((sheet) => {
        return sheet.sort((a: any, b: any) => {
          return b.prio - a.prio;
        });
      });

      if (sheets.filter((s: any) => s.prio).length != 0) return sortedSheets;
      return sheets;
    } else if (this.type == "Template") {
      const templates = _.chunk(
        this.$store.getters.getTemplatesByType(sheetType),
        4
      );
      const sortedTemplates = templates.map((sheet) => {
        return sheet.sort((a: any, b: any) => {
          return b.prio - a.prio;
        });
      });
      if (templates.filter((t: any) => t.prio).length != 0)
        return sortedTemplates;
      return templates;
    }
  }

  get positionalGroups() {
    return this.chunkedSheetsOrTemplates("positional");
  }

  get preflopGroups() {
    return this.chunkedSheetsOrTemplates("preflop");
  }
}
