import { Graph } from "@/types/Graphs";
import { RootState } from "@/types/RootState";
import { collection } from "firebase/firestore";
import { deleteObject, ref, uploadBytes, UploadMetadata } from "firebase/storage";
import { ActionContext } from "vuex";

type FileModuleState = {
}

const filesModule = {
    state: (): FileModuleState => ({
    }),
    getters: {
    },
    actions: {
        async deleteGraphFile(context: ActionContext<FileModuleState, RootState>, payload: Graph) {
            const fileRef = ref(context.rootState.storage, `graphs/${context.getters.getUser.uid}/${payload.fileName}`)
            try {
                await deleteObject(fileRef)
                context.commit("removeGraph", payload)
            } catch (error) {
                context.commit("removeGraph", payload)
            }
        },
    },
    mutations: {
    },
}

export { filesModule }