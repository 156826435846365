




















import Vue from "vue";
import Component from "vue-class-component";
import ChangePassword from "@/components/Settings/ChangePassword.vue";
import DeleteAccount from "@/components/Settings/DeleteAccount.vue";
import ChangeEmail from "@/components/Settings/ChangeEmail.vue";

@Component({
  components: { ChangePassword, ChangeEmail, DeleteAccount },
})
export default class SettingsPanel extends Vue {}
