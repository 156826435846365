
























































import FeedbackEditor from "@/components/Shared/FeedbackEditor.vue";
import Vue from "vue";
import Component from "vue-class-component";
import { Watch } from "vue-property-decorator";

@Component({
  components: {
    FeedbackEditor,
  },
})
export default class Coaching extends Vue {
  selectedTeamIndex: any = null;
  selectedUser = null;

  @Watch("selectedTeamIndex")
  onSelectedTeamIndexChanged() {
    this.selectedUser = null;
  }

  get teams() {
    return this.$store.getters.getTeams;
  }

  get selectedTeam() {
    return this.teams[this.selectedTeamIndex];
  }

  get users() {
    if (this.selectedTeam && this.selectedTeam.members.length > 0) {
      const members = this.selectedTeam.membership.members.map(
        (member: { email: string; id: string }) => {
          return {
            name: member.email,
            id: member.id,
          };
        }
      );
      return [
        { header: `Team: "${this.selectedTeam.name}"` },
        ...members.filter((member: { name: any }) => {
          return member.name != this.$store.getters.getUser.email;
        }),
      ];
    } else return [];
  }
}
