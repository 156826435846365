import { Analytics } from "firebase/analytics";
import { FirebaseApp } from "firebase/app";
import { Firestore } from "firebase/firestore";
import { FirebaseStorage } from "firebase/storage";
import { Functions } from "firebase/functions";


class RootState {

  constructor(sdk: { firebaseApp: FirebaseApp, firebaseAnalytics: Analytics, firebaseFirestore: Firestore, firebaseStorage: FirebaseStorage, firebaseFunctions: Functions }) {
    this.firebase = sdk.firebaseApp;
    this.analytics = sdk.firebaseAnalytics;
    this.firestore = sdk.firebaseFirestore;
    this.storage = sdk.firebaseStorage;
    this.functions = sdk.firebaseFunctions;
    this.loading = false;
    this.rangeColors = {
      inner: "(0, 90, 0)",
      slight: "(105, 172, 105)",
      medium: "(209, 136, 0)",
      severe: "(255, 0, 0)",
    };
  }
  /* RGB color values */
  rangeColors: {
    inner: string;
    slight: string;
    medium: string;
    severe: string;
  };
  loading: boolean;
  session: any;
  firebase: FirebaseApp;
  analytics: Analytics;
  firestore: Firestore;
  storage: FirebaseStorage;
  functions: Functions;
}
export { RootState };
