import { Graph } from "@/types/Graphs"
import { RootState } from "@/types/RootState"
import { collection, query, where, getDocs, deleteDoc, doc, updateDoc } from "firebase/firestore"
import { getDownloadURL, ref, uploadBytes } from "firebase/storage"
import { ActionContext } from "vuex"

type GraphModuleState = {
    graphs: Graph[]
}

const graphsModule = {
    state: () => ({
        graphs: []
    }),
    getters: {
        getGraphs: (state: GraphModuleState) => {
            return state.graphs
        },
    },
    actions: {
        async fetchGraphsByDate(
            context: ActionContext<GraphModuleState, RootState>, payload: { date: Array<any> }
        ) {
            if (payload.date.length > 0) {
                context.commit("clearGraphs")
                const db = context.rootState.firestore
                const uid = context.getters.getUser.uid
                const storage = context.rootState.storage

                const graphsRef = collection(db, "graphs");
                const graphsQuery = query(graphsRef, where("uid", "==", uid), where("date", "in", payload.date));
                const graphsQuerySnapshot = await getDocs(graphsQuery);
                const graphs = graphsQuerySnapshot.docs.flatMap((graph: any) => { return { id: graph.id, ...graph.data() } })

                const graphsWithUrl = await Promise.all(graphs.flatMap(async (graph) => {
                    try {
                        const graphFileRef = ref(storage, graph.path)
                        const url = await getDownloadURL(graphFileRef)

                        return { url, ...graph }
                    } catch (error) {
                        return graph
                    }

                }))
                context.commit("addGraphs", graphsWithUrl)
            }
        },
        async persistGraph(context: ActionContext<GraphModuleState, RootState>, payload: Graph) {
            const db = context.rootState.firestore

            const graphRef = doc(db, "graphs", payload.id);
            await updateDoc(graphRef, payload);
            context.commit("updateGraph", payload)
        },
        async createGraph(
            context: ActionContext<RootState, RootState>,
            payload: { directory: string, file: File, name: string, docName: string, date: any }
        ) {
            const filesRef = ref(context.rootState.storage, `${payload.directory}/${context.getters.getUser.uid}/${payload.name}`)
            const uploadedFile = await uploadBytes(filesRef, payload.file)
            context.dispatch("createDocument", { collection: "graphs", dataObj: { fileName: uploadedFile.metadata.name, date: payload.date, uid: context.getters.getUser.uid, path: uploadedFile.ref.toString(), label: payload.docName } })
        },
        async deleteGraph(context: ActionContext<GraphModuleState, RootState>, payload: Graph) {
            const db = context.rootState.firestore

            const sheetRef = doc(db, "graphs", payload.id);
            await deleteDoc(sheetRef);
            context.dispatch("deleteGraphFile", payload)
        },
    },
    mutations: {
        addGraph(state: GraphModuleState, payload: Graph) {
            state.graphs.push(payload);
        },
        addGraphs(state: GraphModuleState, payload: Array<Graph>) {
            state.graphs.push(...payload)
        },
        updateGraph(state: GraphModuleState, payload: Graph) {
            state.graphs = state.graphs.map((graph) => {
                if (graph.id === payload.id) {
                    return payload
                }
                return graph
            })
        },
        removeGraph(state: GraphModuleState, payload: Graph) {
            state.graphs = state.graphs.filter((graph) => graph.id !== payload.id);
        },
        clearGraphs(state: GraphModuleState) {
            state.graphs = []
        },
    },
}

export { graphsModule }