


































































































































































































































import { ImportSplit } from "@/types/Sheets";
import { DocumentReference } from "firebase/firestore";
import { parse } from "papaparse";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { positionalRegex } from "@/util/regex";

@Component({
  mixins: [validationMixin],
  validations: {
    form: {
      csvFile: {
        required,
      },
    },
  },
})
export default class DivideTemplateDialog extends Vue {
  @Prop() showDivideDialog!: boolean;

  form: { csvFile: File | null } = { csvFile: null };
  csvData: any = null;
  csvHeaders: any = null;
  csvItems: any = null;
  csvFileName: any = null;

  positionalSheetPositions: any = null;

  splitName: string = "";
  partsToSplit: number | null = null;
  splitTemplateHeaders: string[][] = [];
  templateNames: string[] = [];
  selectedTemplateImportKind: ImportSplit["templateType"] | null = null;

  displaySuccessSnackbar = false;
  displayFailSnackbar = false;

  templateImportKinds = [
    { header: "Template Kinds" },
    {
      name: "Positional Analysis",
      id: "positional",
    },
    {
      name: "Preflop Analysis",
      id: "preflop",
    },
  ];

  get disableTemplateButton() {
    const emptyNames = this.templateNames.filter((name) => name).length == 0;
    return (
      !this.selectedTemplateImportKind ||
      emptyNames ||
      this.splitName.length == 0
    );
  }

  splitTemplate() {
    if (this.partsToSplit && this.partsToSplit > 0) {
      var templates = [];
      var counter = 0;
      while (counter < this.partsToSplit) {
        templates.push([]);
        counter++;
      }
      this.splitTemplateHeaders = templates;
    }
    return [];
  }

  async importFromCSV() {
    try {
      parse(this.form.csvFile as any, {
        worker: true,
        skipEmptyLines: true,
        complete: async (results: { data: string[][]; errors: any[] }) => {
          results as { data: string[][]; errors: any[] };
          this.csvData = results.data;
          if (
            this.selectedTemplateImportKind == "preflop"
          ) {
            this.csvHeaders = results.data[0].map((s: string) => {
              return s;
            });
            /* deduplify csvheaders */
            this.csvHeaders = [...new Set(this.csvHeaders)];
          }
          else if (this.selectedTemplateImportKind == "positional") {
            const regex = positionalRegex.whole;
            const regexStat = positionalRegex.stat;
            const regexPosition = positionalRegex.position;
            const stats = [
              ...new Set(
                results.data[0]
                  .filter((t) => regex.test(t))
                  .map((t) => {
                    const stat = regexStat.exec(t)![0];
                    return stat.slice(2, stat.length - 2);
                  })
              ),
            ];
            const positions = [
              ...new Set(
                results.data[0]
                  .filter((t) => regex.test(t))
                  .map((t) => {
                    const position = regexPosition.exec(t)![0];
                    return position.slice(2, position.length - 2);
                  })
              ),
            ];

            this.positionalSheetPositions = positions;
            this.csvHeaders = stats;
          }
          this.csvItems = [];
          this.csvFileName = this.form.csvFile?.name;
        },
      });
    } catch (error) {
      process.env.NODE_ENV == "development" && console.log(error);
    }
  }

  async createTemplates() {
    try {
      this.$store.commit("setLoading", true);

      const splitData: ImportSplit = {
        headers: this.csvHeaders,
        splitCount: this.partsToSplit!,
        name: this.splitName,
        read: [this.$store.getters.getUser.uid],
        write: [this.$store.getters.getUser.uid],
        templateType: this.selectedTemplateImportKind!,
        splitTemplates: [],
      };
      const split: DocumentReference<any> = await this.$store.dispatch(
        "createDocument",
        {
          collection: "importSplits",
          dataObj: { ...splitData, splitTemplates: [] },
        }
      );

      const templateObjects = this.serializeTemplates(split);
      const templateIDsPromise = templateObjects?.map(async (tObj) => {
        const newT: DocumentReference<any> = await this.$store.dispatch(
          "createDocument",
          {
            collection: "templates",
            dataObj: tObj,
          }
        );

        this.$store.commit("addTemplate", {
          id: newT.id,
          ...tObj,
        });

        return newT.id;
      });

      const templateIDs =
        templateIDsPromise && (await Promise.all(templateIDsPromise));

      await this.$store.dispatch("updateDocument", {
        collection: "importSplits",
        id: await split.id,
        dataObj: { ...splitData, splitTemplates: templateIDs },
      });
      this.$store.commit("addSplit", {
        ...splitData,
        id: await split.id,
        splitTemplates: templateIDs,
      });
      this.displaySuccessSnackbar = true;
      this.$store.commit("setLoading", false);
    } catch (error) {
      this.$store.commit("setLoading", false);

      this.displayFailSnackbar = true;
      console.log(error);
    }
  }

  serializeTemplates(split: DocumentReference<any>) {
    if (this.selectedTemplateImportKind == "preflop") {
      const firstColumn = this.csvData
        .map((row: any[], i: number) => i >= 0 && row[0])
        .filter((c: any) => c);
      return this.serializeMicroTemplate(firstColumn, split);
    } else if (this.selectedTemplateImportKind == "positional") {
      const firstColumn = this.positionalSheetPositions;
      return this.serializeMicroTemplate(firstColumn, split);
    }
  }

  serializeMicroTemplate(firstColumn: any[], split: any) {
    console.log(firstColumn);
    return this.splitTemplateHeaders.map((headerArr, i) => {
      const thresholds = firstColumn.map((column: any) => {
        return {
          position: column,
          thresholds: headerArr.map((h) => {
            return {
              category: h,
              medium_top: 6,
              medium_bottom: 1,
              slight_top: 5,
              slight_bottom: 2,
              inner_top: 4,
              inner_bottom: 3,
            };
          }),
        };
      });

      const rows = firstColumn
        .map((column: any, i: number) => {
          if (
            (i >= 0 && this.selectedTemplateImportKind == "positional") ||
            (i > 0 && this.selectedTemplateImportKind == "preflop")
          ) {
            const row: any = { position: column };
            for (const h of headerArr) {
              row[h] = "";
            }
            return row;
          }
        })
        .filter((r: any) => r);
      const userId = this.$store.getters.getUser.uid;

      return {
        split: split.id,
        label: this.templateNames[i],
        thresholds: thresholds,
        rows: rows,
        type: this.selectedTemplateImportKind,
        read: [userId],
        write: [userId],
      };
    });
  }

  get fileErrors() {
    const errors: any = [];
    if (!this.$v.form.csvFile!.$dirty) return errors;
    !this.$v.form.csvFile!.required &&
      errors.push("A file is required for uploading.");
    /* !this.$v.form.csvFile!.isCSV && errors.push("You can only upload CSV files!"); */
    return errors;
  }
}
