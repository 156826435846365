<template>
  <notes-panel></notes-panel>
</template>

<script>
import Vue from "vue";
import Component from "vue-class-component";
import NotesPanel from "@/components/Notes/NotesPanel.vue";

@Component({
  components: { NotesPanel },
})
export default class Notes extends Vue {
  async created() {
    this.$store.commit("setLoading", true);

    await this.$store.dispatch("setNoteState");

    this.$store.commit("setLoading", false);
  }
}
</script>

<style>
</style>