























import _ from "lodash-es"
import { Prop, Component, Vue } from "vue-property-decorator";
import EditMacroThresholds from "@/components/Templates/ContextMenu/Dialog/Macro/Thresholds/EditMacroThresholds.vue";
// eslint-disable-next-line no-unused-vars
import { VariableSheet } from "@/types/Sheets";
// eslint-disable-next-line no-unused-vars

@Component({
  components: {
    EditMacroThresholds,
  },
})
export default class MacroThresholds extends Vue {
  @Prop() template!: VariableSheet;

  /* Returns array of thresholds */
  get categories(): string[] {
    let categoryArr: string[] = [];

    this.template.thresholds.forEach((thresholdObj: any) => {
      categoryArr.push(thresholdObj.category!);
    });

    return categoryArr;
  }

  get categoryGroups() {
    return _.chunk(this.categories, 2);
  }
}
