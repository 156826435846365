























































































// eslint-disable-next-line no-unused-vars
import { DocumentReference } from "firebase/firestore";
import { Component, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {},
})
export default class NotesEditor extends Vue {
  config = { modules: { toolbar: {} } };
  selectedNoteIndex: number | null = 1;

  @Watch("selectedNoteIndex")
  onSelectedNoteIndexChanged() {
    // @ts-ignore
    this.$refs.notesQuill?.editor.setContents(this.selectedNote.ops);
  }

  get notes() {
    return this.$store.getters.getNotes;
  }

  get selectedNote() {
    return this.selectedNoteIndex
      ? this.notes[this.selectedNoteIndex - 1]
      : null;
  }

  async createNote() {
    try {
      const userId = this.$store.getters.getUser.uid;
      const newNoteObj = {
        ops: [],
        read: [userId],
        write: [userId],
      };

      this.$store.commit("setLoading", true);

      const newNote: DocumentReference<any> = await this.$store.dispatch(
        "createDocument",
        {
          collection: "notes",
          dataObj: newNoteObj,
        }
      );

      this.$store.commit("addNote", { id: newNote.id, ...newNoteObj });
      this.$store.commit("setLoading", false);
    } catch (error) {
      console.log(error);
      this.$store.commit("setLoading", false);
    }
  }

  async deleteNote() {
    this.$store.commit("setLoading", true);

    await this.$store.dispatch("deleteNote", this.selectedNote);
    this.$store.commit("setLoading", false);

    this.selectedNoteIndex = null;
  }

  async saveNote() {
    console.log(this.selectedNote.ops);

    this.$store.commit("setLoading", true);

    await this.$store.dispatch("persistNote", {
      id: this.selectedNote.id,
      read: [this.$store.getters.getUser.uid],
      write: [this.$store.getters.getUser.uid],
      ops: this.selectedNote.ops.ops,
    });
    this.$store.commit("setLoading", false);
  }
}
