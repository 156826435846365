


















































































































import Vue from "vue";
import Component from "vue-class-component";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { parse, ParseResult } from "papaparse";
import { Prop } from "vue-property-decorator";
import { positionalRegex } from "@/util/regex";
import {
  ImportSplit,
  PositionalSheet,
  PreflopSheet,
  VariableSheet,
} from "@/types/Sheets";

@Component({
  mixins: [validationMixin],
  validations: {
    form: {
      csvFile: {
        required,
      },
    },
  },
})
export default class CSVImportDialog extends Vue {
  @Prop() showCSVDialog!: boolean;
  @Prop() parsingTemplate?: PreflopSheet | PositionalSheet;

  positionalSheetParsed:
    | ({ stat: string; position: string; value: string } | undefined)[]
    | undefined;

  showImportFailSnackbar: boolean = false;
  showImportSuccessSnackbar: boolean = false;
  loading = false;

  /* On CSV Sheet Import */
  selectedSplit: string | null = null;
  get splits() {
    const count = (position: ImportSplit["templateType"]) =>
      this.$store.getters.getSplits.filter(
        (s: ImportSplit | undefined) => s?.templateType == position
      ).length;

    const positionalCount = count("positional");
    const preflopCount = count("preflop");

    const part = (
      count: number,
      position: ImportSplit["templateType"],
      header: string
    ) =>
      count > 0
        ? [
            { header: header },
            ...this.$store.getters.getSplits.map(
              (s: ImportSplit | undefined) => {
                return s && s.templateType === position
                  ? { name: s.name, id: s.id }
                  : null;
              }
            ),
          ]
        : null;

    const positionalPart = part(
      positionalCount,
      "positional",
      "Positional Analysis Exports"
    );
    const preflopPart = part(
      preflopCount,
      "preflop",
      "Preflop Analysis Exports"
    );

    return [positionalPart, preflopPart]
      .flatMap((part) => part)
      .filter((p) => p);
  }

  form: { csvFile: File | null } = { csvFile: null };

  async importFromCSV() {
    try {
      parse(this.form.csvFile as any, {
        worker: true,
        skipEmptyLines: true,
        complete: async (results) => {
          results as { data: string[][]; errors: any[] };
          if (results) this.importTemplateFromCSV(results);
        },
      });
    } catch (error) {
      process.env.NODE_ENV == "development" && console.log(error);
    }
  }

  async importTemplateFromCSV(results: ParseResult<unknown>) {
    this.loading = true;
    this.$emit("update:loading", this.loading);
    try {
      var splitObj: ImportSplit | undefined;
      if (this.parsingTemplate) {
        splitObj = this.$store.getters.getSplitById(
          this.parsingTemplate.split
        ) as ImportSplit | undefined;
      } else {
        splitObj = this.$store.getters.getSplitById(this.selectedSplit) as
          | ImportSplit
          | undefined;
      }

      const firstRow = results.data[0] as string[] | null;

      this.positionalSheetParsed = firstRow?.map((positionStat, i) => {
        const regex = positionalRegex.whole;
        const regexStat = positionalRegex.stat;
        const regexPosition = positionalRegex.position;

        const fitsRegex = regex.test(positionStat);

        if (fitsRegex) {
          const stat = regexStat.exec(positionStat)![0];
          const position = regexPosition.exec(positionStat)![0];

          return {
            stat: stat.slice(2, stat.length - 2),
            position: position.slice(2, position.length - 2),
            value: (results as { data: string[][] }).data[1][i],
          };
        }
      });

      const unfitHeaders = firstRow!.filter(
        (r) => !splitObj!.headers.includes(r)
      );

      const unfitHeadersPositional = this.positionalSheetParsed?.filter(
        (h) => !splitObj!.headers.includes(h ? h.stat : "")
      );

      if (unfitHeaders.length == 0 || unfitHeadersPositional?.length == 0) {
        if (this.parsingTemplate) {
          if (this.parsingTemplate.type == "preflop") {
            const rows = this.preflopRows(this.parsingTemplate, results);
            await this.addToExistingSheet({
              label: this.parsingTemplate.label,
              split: this.parsingTemplate.split,
              rows: rows,
              read: [this.$store.getters.getUser.uid],
              write: [this.$store.getters.getUser.uid],
              type: this.parsingTemplate.type,
              thresholds: this.parsingTemplate.thresholds,
            });
          } else if (this.parsingTemplate.type == "positional") {
            const rows = this.positionalRows(this.parsingTemplate);
            await this.addToExistingSheet({
              label: this.parsingTemplate.label,
              split: this.parsingTemplate.split,
              rows: rows,
              read: [this.$store.getters.getUser.uid],
              write: [this.$store.getters.getUser.uid],
              type: this.parsingTemplate.type,
              thresholds: this.parsingTemplate.thresholds,
            });
          }
        } else {
          splitObj?.splitTemplates.forEach(async (tId) => {
            const template: VariableSheet = JSON.parse(
              JSON.stringify(this.$store.getters.getTemplateById(tId))
            );

            template.id && delete template.id;

            if (template.type == "preflop") {
              template as PreflopSheet;

              const rows = this.preflopRows(template, results);

              await this.persistSerializedSheet({
                label: template.label,
                split: template.split,
                sheets: [{ ...template, rows: rows }],
                read: [this.$store.getters.getUser.uid],
                write: [this.$store.getters.getUser.uid],
                type: template.type,
                thresholds: template.thresholds,
              });
            } else if (template.type == "positional") {
              template as PositionalSheet;

              const rows = this.positionalRows(template);

              await this.persistSerializedSheet({
                label: template.label,
                split: template.split,
                sheets: [{ ...template, rows: rows }],
                read: [this.$store.getters.getUser.uid],
                write: [this.$store.getters.getUser.uid],
                type: template.type,
                thresholds: template.thresholds,
              });
            }
          });
        }

        this.showImportSuccessSnackbar = true;
      } else this.showImportFailSnackbar = true;
    } catch (error) {
      console.log(error);
      this.showImportFailSnackbar = true;
    }
    this.loading = false;
    this.$emit("update:loading", this.loading);
  }

  async persistSerializedSheet(sheet: any) {
    this.$store.commit("setLoading", true);

    const newSheet = await this.$store.dispatch("createDocument", {
      collection: "sheets",
      dataObj: sheet,
    });
    this.$store.commit("addSheet", {
      id: newSheet.id,
      ...sheet,
    });
    this.$store.commit("setLoading", false);
  }

  async addToExistingSheet(sheet: any) {
    this.$store.commit("setLoading", true);

    await this.$store.dispatch("persistSheet", {
      ...this.parsingTemplate,
      sheets: [...this.parsingTemplate!.sheets, sheet],
    });

    this.$store.commit("setLoading", false);
  }

  preflopRows(template: PreflopSheet, results: ParseResult<unknown>) {
    const rowsToMap = this.parsingTemplate
      ? template.sheets[template.sheets.length - 1].rows
      : template.rows;
    return rowsToMap.map((r: any, i: any) => {
      const values = (results.data[0] as any)
        .map((x: any, j: any) =>
          Object.keys(r).includes(x)
            ? { value: (results.data[i + 1] as any)[j], key: x }
            : null
        )
        .filter((x: any) => x !== null);

      for (const key of Object.keys(r)) {
        const obj = (values as { key: string; value: string }[]).find(
          (valObj) => valObj.key == key
        );
        if (obj) r[key] = obj.value;
      }
      return r;
    });
  }

  positionalRows(template: PositionalSheet) {
    const rowsToMap = this.parsingTemplate
      ? template.sheets[template.sheets.length - 1].rows
      : template.rows;
    return rowsToMap.map((r: any) => {
      Object.keys(r).forEach((k) => {
        if (k != "position") {
          const value = this.positionalSheetParsed?.find(
            (s) => s?.stat == k && s.position == r.position
          )?.value;
          r[k] = value ? value : "-";
        }
      });
      return r;
    });
  }

  get fileErrors() {
    const errors: any = [];
    if (!this.$v.form.csvFile!.$dirty) return errors;
    !this.$v.form.csvFile!.required &&
      errors.push("A file is required for uploading.");
    /* !this.$v.form.csvFile!.isCSV && errors.push("You can only upload CSV files!"); */
    return errors;
  }
}
