



























































































































































































import Vue from "vue";
import Component from "vue-class-component";
import DialogGeneral from "@/components/Templates/ContextMenu/Dialog/General/DialogGeneral.vue";
import MacroThresholds from "@/components/Templates/ContextMenu/Dialog/Macro/Thresholds/MacroThresholds.vue";
import MacroColumns from "@/components/Templates/ContextMenu/Dialog/Macro/Columns/MacroColumns.vue";
import MicroThresholds from "@/components/Templates/ContextMenu/Dialog/Micro/Thresholds/MicroThresholds.vue";
import MicroColumns from "@/components/Templates/ContextMenu/Dialog/Micro/Columns/MicroColumns.vue";
import SplitDisplay from "@/components/Shared/SplitDisplay.vue";
import { Prop } from "vue-property-decorator";
// eslint-disable-next-line no-unused-vars
import { ImportSplit, VariableSheet } from "@/types/Sheets";

@Component({
  components: {
    DialogGeneral,
    MacroColumns,
    MacroThresholds,
    MicroThresholds,
    MicroColumns,
    SplitDisplay,
  },
})
export default class TemplateContextMenu extends Vue {
  @Prop() template!: VariableSheet;

  showEditDialog = false;
  showDeleteDialog = false;
  showSplitDisplay = false;

  headers = [
    { text: "Team Name", value: "name" },
    {
      text: "Allow Usage of Template",
      value: "permission",
      sortable: false,
    },
  ];

  get teams() {
    return this.$store.getters.getTeamPermissionsByTemplate(this.template);
  }

  get relatedSplit(): ImportSplit | null {
    return this.$store.getters.getSplitById(this.template.split);
  }

  async addPermission(item: any) {
    const permissionsRead = [this.template.read, `team:${item.id}`].flat();

    await this.$store.dispatch("persistTemplate", {
      ...this.template,
      read: permissionsRead,
    });
  }

  async removePermission(item: any) {
    const permissionsRead = this.template.read.filter(
      (permission) => permission !== `team:${item.id}`
    );
    await this.$store.dispatch("persistTemplate", {
      ...this.template,
      read: permissionsRead,
    });
  }

  get isMicroSheetTemplate() {
    return (
      this.template.type == "positional" || this.template.type == "preflop"
    );
  }

  saveTemplate() {
    this.showEditDialog = false;
    this.$emit("save-template");
  }
}
