import store from "@/store";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { NavigationGuard, NavigationGuardNext, Route } from "vue-router";
const loggedInRoutes = ["Sheets", "Graphs", "Notes", "Settings"]

const navigationGuard: NavigationGuard<Vue> = (to, from, next) => {
    if (store.getters.getQueriedUser) {
        continueRoute(to, from, next)
    }
    else {
        const auth = getAuth();
        onAuthStateChanged(auth, async (user) => {
            store.commit("setUser", user);

            if (user) {
                console.log("begin processing")
                await store.dispatch("queryTeams");
                await store.dispatch("queryIsAdmin")
                await store.dispatch("setTemplateState");
                await store.dispatch("setPopulationState");
                await store.dispatch("setSheetState");
                console.log("end processing")

            }

            setTimeout(() => {
                console.log("mount app")

                store.commit("setQueriedUser", true)
                continueRoute(to, from, next)
            }, 2000);
        });
    }

}
const continueRoute = (to: Route, from: Route, next: NavigationGuardNext) => {
    const isLoggedIn = store.getters.getUser
    /* Guarded Routes (Must be Logged In) */
    if (loggedInRoutes.includes(to.name!)) {
        isLoggedIn ? next() : next({ name: "Login" })
    }
    /* Login Route Guard (Must be Logged Out) */
    else if (to.name == "Login") {
        isLoggedIn ? next({ name: "Landing" }) : next()
    }
    /* Admin Route Guard (Must be Logged In & Admin) */
    else if (to.name == "Admin" || to.name == "Templates") {
        if (isLoggedIn && store.getters.isAdmin) next()
        else next({ name: "Login" })
    }
    else next()
}
export default navigationGuard