



































































































































































import { Component, Vue } from "vue-property-decorator";
import { getAuth, signOut } from "firebase/auth";
import { mapGetters } from "vuex";

@Component({
  components: {},
  name: "App",
  computed: mapGetters({ appWideLoading: "getLoading" }),
})
export default class App extends Vue {
  loggedOut: boolean = false;
  displayDrawer = this.$vuetify.breakpoint.lg ? true : false;

  restrictedItems = [
    { icon: "text_snippet", name: "Notes", routeName: "Notes" },
    { icon: "table_view", name: "Sheets", routeName: "Sheets" },
    { icon: "collections", name: "Gallery", routeName: "Graphs" },
    { icon: "settings", name: "Settings", routeName: "Settings" },
  ];

  get navItems() {
    if (this.$store.getters.getUser) {
      return this.$store.getters.isAdmin
        ? [
            { icon: "home", name: "Home", routeName: "Landing" },
            { icon: "person", name: "Admin", routeName: "Admin" },
            {
              icon: "filter_frames",
              name: "Templates",
              routeName: "Templates",
            },
            ...this.restrictedItems,
          ]
        : [
            { icon: "home", name: "Home", routeName: "Landing" },
            ...this.restrictedItems,
          ];
    }
    return [
      { icon: "home", name: "Home", routeName: "Landing" },
      { icon: "login", name: "Login", routeName: "Login" },
    ];
  }

  get shouldAlignCenter() {
    if (
      this.$route.name == "Login" ||
      this.$route.name == "Not Found" ||
      this.$route.name == "ForgotPassword" ||
      this.$route.name == "Invites" ||
      this.$route.name == "Landing" ||
      this.$route.name == "Imprint" ||
      this.$route.name == "Reset"
    )
      return true;
    return false;
  }

  async logOut() {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        this.$store.commit("setUser", null);
        this.$router.push({ name: "Landing" });
        this.$router.go(0);
        this.loggedOut = true;
      })
      .catch((error) => {
        process.env.NODE_ENV == "development" && console.log(error);
      });
  }
}
