































// eslint-disable-next-line no-unused-vars
import { Graph } from "@/types/Graphs";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class GraphSingleView extends Vue {
  @Prop() graph!: Graph;

  goToImage() {
    window.open(this.graph.url, "_blank");
  }
}
