


















































































































import { PositionalSheet, PreflopSheet } from "@/types/Sheets";
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import PickerItemsCard from "./PickerItemsCard.vue";

@Component({ components: { PickerItemsCard } })
export default class PickerItems extends Vue {
  @Prop() positionalGroups!: PositionalSheet[];
  @Prop() preflopGroups!: PreflopSheet[];
  @Prop() type!: "Sheet" | "Template";

  colorCode(s: string) {
    return "#" + Buffer.from(s).toString("hex").substring(0, 6);
  }

  isActive(item: any) {
    if (this.$route.params.id == item.id) return "#43a047";
    return "";
  }

  to(item: any) {
    return { path: `/${this.type.toLowerCase()}s/${item.id}` };
  }
}
