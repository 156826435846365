import { RootState } from "@/types/RootState";
import { PositionalSheet, PreflopSheet, VariableSheet } from "@/types/Sheets"
import { collection } from "firebase/firestore";
import { query, where, getDocs, deleteDoc, doc, updateDoc } from "firebase/firestore";
import { ActionContext } from "vuex";

type SheetModuleState = {
    sheets: Array<VariableSheet>
}

const sheetsModule = {
    state: (): SheetModuleState => ({
        sheets: []
    }),
    getters: {
        getSheetsByType: (state: SheetModuleState) => (
            sheetType: "positional" | "preflop",
        ) => {
            return state.sheets.filter((sheet) => sheet.type === sheetType);
        },
        getSheet: (state: SheetModuleState) => (id: string) => {
            return state.sheets.find((sheet) => sheet.id === id);
        },
    },
    actions: {
        async setSheetState(
            context: ActionContext<SheetModuleState, RootState>,
        ) {
            const sheetTypes = ["positional", "preflop"];

            const sheetsBatch = sheetTypes.flatMap(async (type) => {
                const db = context.rootState.firestore
                const uid = context.getters.getUser.uid

                const sheetsRef = collection(db, "sheets");
                const q = query(sheetsRef, where("type", "==", type), where("write", "array-contains", uid));
                const querySnapshot = await getDocs(q);
                return querySnapshot.docs.flatMap((sheet) => { return { id: sheet.id, ...sheet.data() } })
            })
            const sheetsArr = (await Promise.all(sheetsBatch)).flat()
            context.commit("addSheets", sheetsArr)
        },
        async persistSheet(context: ActionContext<SheetModuleState, RootState>, payload: VariableSheet) {
            const db = context.rootState.firestore

            const sheetRef = doc(db, "sheets", payload.id!);
            await updateDoc(sheetRef, payload);
            context.commit("updateSheet", payload)
        },
        async deleteSheet(context: ActionContext<SheetModuleState, RootState>, payload: VariableSheet) {
            const db = context.rootState.firestore

            const sheetRef = doc(db, "sheets", payload.id!);
            await deleteDoc(sheetRef);
            context.commit("removeSheet", payload)
        },
        async deleteSheetsSplit(context: ActionContext<SheetModuleState, RootState>, splitId: string ) {
            const db = context.rootState.firestore
            const sheetsCollection = collection(db, "sheets")
            const splitQuery = query(sheetsCollection, where("split", "==", splitId))
            const querySnapshot = await getDocs(splitQuery)
            const sheets = querySnapshot.docs.flatMap((sheet) => { return { id: sheet.id, ...sheet.data() } })
            console.log(sheets)

            for (const s of sheets) {
                const sheetRef = doc(db, "sheets", s.id!)
                await deleteDoc(sheetRef);
                context.commit("removeSheet", s)
            }
            return;
        },
    },
    mutations: {
        addSheet(state: SheetModuleState, payload: VariableSheet) {
            state.sheets.push(payload);
        },
        addSheets(state: SheetModuleState, payload: Array<VariableSheet>) {
            state.sheets.push(...payload);
        },
        updateSheet(state: SheetModuleState, payload: VariableSheet) {
            state.sheets = state.sheets.map((sheet: VariableSheet) => {
                if (sheet.id === payload.id) {
                    return payload
                }
                return sheet
            })
        },
        removeSheet(state: SheetModuleState, payload: VariableSheet) {
            state.sheets = state.sheets.filter(
                (sheet) => sheet.id !== payload.id
            );
        },
        clearSheets(state: SheetModuleState) {
            state.sheets = []
        },
    },
}

export { sheetsModule }