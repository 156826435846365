






































import Vue from "vue";
import Component from "vue-class-component";
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
// eslint-disable-next-line no-unused-vars
import { Team } from "@/store/modules/teams";

@Component({
  mixins: [validationMixin],
  validations: {
    form: {
      name: {
        required,
      },
    },
  },
})
export default class Teams extends Vue {
  headers = [
    { text: "Team", value: "name" },
    { text: "User Count (incl. You)", value: "userCount" },
    { text: "Remove Team", value: "remove", sortable: false, align: "center" },
  ];

  form = { name: null };

  async validateName() {
    /* Set all elements to "touched"
        Trigger display of validation messages  */
    this.$v.$touch();

    if (!this.$v.$invalid) {
      this.$store.commit("setLoading", true);
      await this.$store.dispatch("createTeam", { name: this.form.name });
      this.$store.commit("setLoading", false);

      this.$v.$reset();
      this.form.name = null;
    }
  }

  async removeTeam(item: Team) {
    this.$store.commit("setLoading", true);
    await this.$store.dispatch("deleteTeam", item);
    this.$store.commit("setLoading", false);
  }

  get teams() {
    return this.$store.getters.getTeams.map((team: Team) => {
      return {
        name: team.name,
        userCount: team.members.length,
        id: team.id,
        membershipId: team.membershipId,
      };
    });
  }

  get nameErrors() {
    const errors: any[] = [];
    if (!this.$v.form.name!.$dirty) return errors;
    !this.$v.form.name!.required && errors.push("Team Name is required!");
    return errors;
  }
}
