









































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { positionalObj, preflopObj } from "@/store/util/dataObjects";
// eslint-disable-next-line no-unused-vars
import { DocumentReference } from "firebase/firestore";
// eslint-disable-next-line no-unused-vars
import { VariableSheet } from "@/types/Sheets";

@Component
export default class PickerDialog extends Vue {
  @Prop() showDialog!: boolean;
  @Prop() type!: string;

  selectedChoice = "";
  sheetTypes = [
    "Positional Analysis Sheet",
    "Preflop Analysis Sheet",
  ];

  async created() {}

  async createItem() {
    if (this.type == "Sheet") {
      if (this.selectedChoice != "") {
        try {
          this.$store.commit("setLoading", true);

          const templateFromStore: VariableSheet =
            this.$store.getters.getTemplateById(this.selectedChoice);

          const id = templateFromStore.id;
          delete templateFromStore!.id;

          const userId = this.$store.getters.getUser.uid;
          const newSheetObj = {
                  sheets: [
                    { ...templateFromStore, id: null, label: "Sheet #1" },
                  ],
                  type: templateFromStore.type,
                  label: templateFromStore.label,
                  read: [userId],
                  write: [userId],
                };

          const newSheet: DocumentReference<any> = await this.$store.dispatch(
            "createDocument",
            {
              collection: "sheets",
              dataObj: newSheetObj,
            }
          );
          templateFromStore!.id = id;

          this.$store.commit("addSheet", { id: newSheet.id, ...newSheetObj });
          this.$store.commit("setLoading", false);
        } catch (error) {
          process.env.NODE_ENV == "development" && console.log(error);
          this.$store.commit("setLoading", false);
        }
      }
    } else if (this.type == "Template") {
      if (this.selectedChoice === "Positional Analysis Sheet") {
        await this.createNewTemplate("positional");
      } else if (this.selectedChoice === "Preflop Analysis Sheet") {
        await this.createNewTemplate("preflop");
      }
    }
  }

  async createNewTemplate(templateType: "preflop" | "positional") {
    try {
      this.$store.commit("setLoading", true);

      const userId = this.$store.getters.getUser.uid;

      const templateObj = (type: string) => {
        if (type == "preflop") return preflopObj;
        else if (type == "positional") return positionalObj;
      };

      const newTemplateObj = {
        read: [userId],
        write: [userId],
        ...templateObj(templateType),
      };

      const newTemplate: DocumentReference<any> = await this.$store.dispatch(
        "createDocument",
        { collection: "templates", dataObj: newTemplateObj }
      );

      this.$store.commit("addTemplate", {
        id: newTemplate.id,
        ...newTemplateObj,
      });
      this.$store.commit("setLoading", false);
    } catch (error) {
      process.env.NODE_ENV == "development" && console.log(error);
      this.$store.commit("setLoading", false);
    }
  }

  get templatesForSelect() {
    const templates = [];
    const positionalTemplates =
      this.$store.getters.getTemplatesByType("positional");

    const preflopTemplates = this.$store.getters.getTemplatesByType("preflop");

    positionalTemplates.length > 0
      ? templates.push({ header: "Positional Stats Sheets" }) &&
        templates.push(...positionalTemplates)
      : null;

    preflopTemplates.length > 0
      ? templates.push({ header: "Preflop Analysis Sheets" }) &&
        templates.push(...preflopTemplates)
      : null;

    return templates;
  }

  cardTitle() {
    return this.type == "Sheet"
      ? "Select a Template"
      : "Select a Template Type";
  }

  cardText() {
    return this.type == "Sheet"
      ? "Select a Template From the Dropdown Menu below."
      : "Select a Template Type From the Dropdown Menu below.";
  }
}
