























































import HotTable, { HotColumn } from "@handsontable/vue";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({
  components: {
    HotTable,
    HotColumn,
  },
})
export default class MacroSheet extends Vue {
  @Prop() color!: string;
  @Prop() isLine!: boolean;
}
