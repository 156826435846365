












import { Component, Vue } from "vue-property-decorator";
import _ from "lodash-es"
import GraphSingleView from "@/components/Graphs/SingleView/GraphSingleView.vue";

@Component({
  components: {
    GraphSingleView,
  },
})
export default class GraphPanel extends Vue {
  get graphs() {
    return _.chunk(this.$store.getters.getGraphs, 4);
  }
}
