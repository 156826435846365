

























































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {},
})
export default class FeedbackEditor extends Vue {
  @Prop() userId!: string;
  content: any = { ops: [] };
  config = { modules: { toolbar: {} } };
  selectedFeedbackIndex = 1;

  @Watch("selectedFeedbackIndex")
  onSelectedNoteIndexChanged() {
    this.setContent();
  }

  @Watch("userId")
  onUserIdChanged() {
    this.setContent();
  }

  async created() {
    this.$store.commit("setLoading", true);

    await this.$store.dispatch("setFeedbackState");
    this.$store.commit("setLoading", false);
  }

  get feedback() {
    return this.$store.getters.getFeedback;
  }

  get selectedFeedback() {
    return this.feedbackForSelectedUser[this.selectedFeedbackIndex - 1];
  }

  get feedbackForSelectedUser() {
    return this.feedback.filter((feedback: { read: string[] }) => {
      return feedback.read.includes(this.userId);
    });
  }

  setContent() {
    // @ts-ignore
    this.$refs.feedbackQuill?.editor.setContents(this.selectedFeedback?.ops);
  }

  async deleteFeedback() {
    this.$store.commit("setLoading", true);

    await this.$store.dispatch("deleteFeedback", this.selectedFeedback!);
    this.$store.commit("setLoading", true);

    this.selectedFeedbackIndex =
      this.selectedFeedbackIndex > 1 ? this.selectedFeedbackIndex - 1 : 1;
  }

  async saveFeedback() {
    this.$store.commit("setLoading", true);

    await this.$store.dispatch("persistFeedback", {
      ...this.selectedFeedback,
      ops: this.selectedFeedback.ops.ops,
    });

    this.$store.commit("setLoading", false);
  }

  async createFeedback() {
    try {
      const userId = this.$store.getters.getUser.uid;
      const newFeedbackObj = {
        ops: [],
        read: [userId, this.userId],
        write: [userId],
        author: this.$store.getters.getUser.email,
      };

      this.$store.commit("setLoading", true);

      const newFeedback = await this.$store.dispatch("createDocument", {
        collection: "feedback",
        dataObj: newFeedbackObj,
      });

      this.$store.commit("addFeedback", {
        id: newFeedback.id,
        ...newFeedbackObj,
      });
      this.$store.commit("setLoading", false);
    } catch (error) {
      console.log(error);
      this.$store.commit("setLoading", false);
    }
  }
}
