


















































import { Component, Vue, Watch } from "vue-property-decorator";
// eslint-disable-next-line no-unused-vars
import { Graph } from "@/types/Graphs";

@Component
export default class GraphPicker extends Vue {
  options = {
    minMatchChartLength: 2,
    keys: ["label"],
  };

  selectedDates = [];

  get selectedTooManyDates() {
    return this.selectedDates.length > 10 ? true : false;
  }

  get selectedManyDates() {
    return this.selectedDates.length > 6 && this.selectedDates.length < 10
      ? true
      : false;
  }

  @Watch("selectedDates")
  async onSelectedDatesChanged(val: any) {
    this.$store.commit("setLoading", true);

    this.selectedTooManyDates
      ? null
      : await this.$store.dispatch("fetchGraphsByDate", { date: val });
    this.$store.commit("setLoading", false);
  }

  get graphs() {
    return this.$store.getters.getGraphs;
  }
}
