














import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import CreateMicroColumns from "@/components/Templates/ContextMenu/Dialog/Micro/Columns/CreateMicroColumns.vue";
// eslint-disable-next-line no-unused-vars
import { VariableSheet } from "@/types/Sheets";

@Component({
  components: {
    CreateMicroColumns,
  },
})
export default class MicroColumns extends Vue {
  @Prop() template!: VariableSheet;

  get categories(): string[] {
    let arr: string[] = [];

    if (this.template.thresholds.length > 0) {
      this.template.thresholds.forEach((thresholdObj: any) =>
        thresholdObj.thresholds.forEach((el: any) => {
          arr.push(el.category!);
        })
      );
    } else return [];

    return [...new Set(arr)];
  }

  get positions(): string[] {
    let arr: string[] = [];

    if (this.template.thresholds.length > 0) {
      this.template.thresholds.forEach((thresholdObj: any) =>
        arr.push(thresholdObj.position)
      );
    } else return [];

    return [...new Set(arr)];
  }

  async deleteColumnChip(category: string) {
    this.$store.commit("setLoading", true);

    const thresholds = this.template.thresholds.map((el: any) => {
      return {
        ...el,
        thresholds: el.thresholds.filter((t: any) => {
          return t.category !== category;
        }),
      };
    });

    const rows = this.template.rows.filter((r: any) => r.category !== category);

    await this.$store.dispatch("persistTemplate", {
      ...this.template,
      thresholds,
      rows,
    });
    this.$store.commit("setLoading", false);
  }

  async deletePositionChip(position: string) {
    this.$store.commit("setLoading", true);

    const thresholds = this.template.thresholds.filter(
      (t: any) => t.position !== position
    );

    const rows = this.template.rows.filter((r: any) => r.position !== position);

    await this.$store.dispatch("persistTemplate", {
      ...this.template,
      thresholds,
      rows,
    });
    this.$store.commit("setLoading", false);
  }
}
