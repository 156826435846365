













import { Component, Vue } from "vue-property-decorator";
import GraphUploadDialog from "@/components/Graphs/GraphUploadDialog.vue";
import GraphPanel from "@/components/Graphs/GraphPanel.vue";
import GraphPicker from "@/components/Graphs/Picker/GraphPicker.vue";

@Component({
  components: {
    GraphUploadDialog,
    GraphPanel,
    GraphPicker,
  },
})
export default class Graphs extends Vue {
  showUploadDialog = false;

  async created() {
  }
}
