






















































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component
export default class PickerToolbar extends Vue {
  @Prop() type!: string;

  caption() {
    if (this.type == "Sheet") return "Your Sheets";
    else if (this.type == "Template") return "Your Templates";
  }
}
