






















import Vue from "vue";
import Component from "vue-class-component";
import NotesEditor from "@/components/Shared/NotesEditor.vue";
import FeedbackReader from "@/components/Shared/FeedbackReader.vue";

@Component({
  components: {
    NotesEditor,
    FeedbackReader,
  },
})
export default class NotesPanel extends Vue {}
