














































































































































































import { HotTable, HotColumn } from "@handsontable/vue";
import Vue from "vue";
import { Prop, Component, Watch } from "vue-property-decorator";
import sheetColors from "@/store/util/sheetColors";
import Handsontable from "handsontable";
// eslint-disable-next-line no-unused-vars
import { Threshold, VariableSheet } from "@/types/Sheets";
import svgPartial from "@/components/Shared/RangeSheets/svgPartial.vue";

@Component({
  components: {
    HotTable,
    HotColumn,
    svgPartial,
  },
})
export default class MicroSheet extends Vue {
  @Prop() sheet!: VariableSheet;
  @Prop() isTemplate!: boolean;
  @Prop() index!: number;

  sheetColors = sheetColors;
  hotData: any = [];
  showRangeSnackbar = false;
  selectedCellThreshold: Threshold | null = null;

  created() {
    this.setHotData();
  }

  setHotData() {
    Vue.set(this, "hotData", this.sheet.rows ? this.sheet.rows : []);
  }

  @Watch("sheet")
  onSheetChanged() {
    const a: any = this.$refs.hotTableComponent;

    this.setHotData();

    a.hotInstance.updateSettings(this.hotSettings);
    a.hotInstance.render();
  }

  get hotSettings() {
    return {
      afterChange: (changes: any) => {
        if (changes != null) {
          this.$store.commit("setLoading", true);
          this.isTemplate
            ? this.$store.dispatch("persistTemplate", {
                ...this.sheet,
                rows: this.hotData,
              })
            : this.$emit("persist-micro-sheet", {
                ...this.sheet,
                index: this.index,
                rows: this.hotData,
              });
          this.$store.commit("setLoading", false);
        }
      },
      afterSelection: (row: any, column: any) => {
        const prop = (
          this.$refs.hotTableComponent as any
        ).hotInstance.colToProp(column);
        if (prop != "label") {
          const position = (
            this.$refs.hotTableComponent as any
          ).hotInstance.getCell(row, 0).innerHTML;
          this.selectedCellThreshold = this.threshold(prop, position);

          this.showRangeSnackbar = true;
        } else {
          this.showRangeSnackbar = false;
          this.selectedCellThreshold = null;
        }
      },
      afterDeselect: () => {
        this.showRangeSnackbar = false;
        this.selectedCellThreshold = null;
      },
      renderer: this.cellRenderer,
      allowInsertColumn: true,
      autoRowSize: false,
      selectionMode: "single",
      comments: true,
      autoColumnSize: false,
      data: this.hotData,
      columns: this.columns,
      colHeaders: this.colHeaders,
      licenseKey: "non-commercial-and-evaluation",
      manualColumnResize: true,
      stretchH: "all",
    };
  }

  cellRenderer(
    instance: any,
    td: HTMLTableCellElement,
    row: number,
    col: number,
    prop: string,
    // eslint-disable-next-line no-unused-vars
    value: any,
    // eslint-disable-next-line no-unused-vars
    cellProperties: any
  ) {
    let args = arguments as any;
    Handsontable.renderers.TextRenderer.apply(this, args);
    // if row contains negative number
    const position = instance.getCell(row, 0).innerHTML;

    if (prop != "position") {
      const threshold = this.threshold(prop, position);
      if (threshold) {
        if (threshold.excludeCell) {
          td.className = "labelMicro";
          td.innerText = "";
          cellProperties.readOnly = true;
          return cellProperties;
        }

        if (threshold.noColors) {
          td.className = "labelMicro";
          return;
        }

        const parsedVal: number = parseFloat(value);

        /* Inner Range */
        if (
          parsedVal <= threshold.inner_top! &&
          parsedVal >= threshold.inner_bottom!
        ) {
          td.style.background = sheetColors.innerTop;
          td.style.fontWeight = "600";
          return;
        }

        /* Inner Top to Severe Top Range */
        if (
          parsedVal <= threshold.slight_top! &&
          parsedVal >= threshold.inner_top!
        ) {
          td.style.background = threshold.flipColors
            ? sheetColors.slightBottom
            : sheetColors.slightTop;
          td.style.fontWeight = "600";
          return;
        } else if (
          parsedVal <= threshold.medium_top! &&
          parsedVal >= threshold.slight_top!
        ) {
          td.style.background = threshold.flipColors
            ? sheetColors.mediumBottom
            : sheetColors.mediumTop;
          td.style.fontWeight = "600";
          return;
        } else if (parsedVal > threshold.medium_top) {
          td.style.background = threshold.flipColors
            ? sheetColors.severeBottom
            : sheetColors.severeTop;
          td.style.fontWeight = "600";
          return;
        }

        /* Inner Bottom to Severe Bottom Range */
        if (
          parsedVal <= threshold.inner_bottom! &&
          parsedVal >= threshold.slight_bottom!
        ) {
          td.style.background = threshold.flipColors
            ? sheetColors.slightTop
            : sheetColors.slightBottom;
          td.style.fontWeight = "600";
          return;
        } else if (
          parsedVal <= threshold.slight_bottom! &&
          parsedVal >= threshold.medium_bottom!
        ) {
          td.style.background = threshold.flipColors
            ? sheetColors.mediumTop
            : sheetColors.mediumBottom;
          td.style.fontWeight = "600";
          return;
        } else if (parsedVal < threshold.medium_bottom) {
          td.style.background = threshold.flipColors
            ? sheetColors.severeTop
            : sheetColors.severeBottom;
          td.style.fontWeight = "600";
          return;
        }
      }
    } else if (prop === "position") {
      td.className = "labelMicro";
    }
  }

  get columns() {
    let a: any[] = [];

    a.push({ data: "position", readOnly: true });

    this.categories.forEach((c) => a.push({ data: c }));

    return a;
  }

  get colHeaders() {
    let a: any[] = [];

    a.push("Positions");

    this.categories.forEach((c) => a.push(c.toUpperCase()));

    return a;
  }

  /* Returns array of unique categories to know how many columns to render */
  get categories(): string[] {
    let categoryArr: string[] = [];

    this.sheet.thresholds.forEach((thresholdObj: any) => {
      thresholdObj.thresholds.forEach((thresholdArr: any) => {
        categoryArr.push(thresholdArr.category!);
      });
    });

    return [...new Set(categoryArr)];
  }

  /* Returns a Range Object for a given category, used for range/color evaluation in the CustomRenderer */
  threshold(category: string, position: string): Threshold | null {
    const matchingPositionThreshold = this.sheet.thresholds.find(
      (t) => t.position == position
    );
    const matchingThreshold = matchingPositionThreshold?.thresholds?.find(
      (t) => t.category == category
    );
    return matchingThreshold
      ? { position: position, ...matchingThreshold }
      : null;
  }
}
