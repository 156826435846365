var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-toolbar',{staticClass:"mb-2 grey darken-4",attrs:{"rounded":""}},[_c('h2',[_vm._v(_vm._s(_vm.caption()))]),_c('v-spacer'),(_vm.type == 'Template')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2 accent darken-1",attrs:{"icon":"","large":""},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('show-divide-dialog')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("call_split")])],1)]}}],null,false,1828625553)},[_c('span',[_vm._v("Import and Split Template from CSV")])]):(_vm.type == 'Sheet')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2 accent",attrs:{"icon":"","large":""},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('show-csv-dialog')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-upload")])],1)]}}])},[_c('span',[_vm._v("Import Sheet from CSV")])]):_vm._e(),(this.type == 'Template')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-1 success",attrs:{"icon":"","large":""},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('show-dialog')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}],null,false,1246400194)},[_c('span',[_vm._v("Create Template")])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }