

























































































import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import { Component, Vue } from "vue-property-decorator";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

@Component({
  name: "LoginComponent",
  mixins: [validationMixin],
  validations: {
    form: {
      mail: {
        required,
        email,
      },
      password: {
        required,
      },
    },
  },
})
export default class LoginComponent extends Vue {
  form = {
    password: null,
    mail: null,
  };

  invalidAuth = false;
  validAuth = false;
  failedAuth = false;
  sending = false;
  showPassword = false;

  clearForm() {
    this.$v.$reset();
    this.form.mail = null;
    this.form.password = null;
  }

  login() {
    this.sending = true;
    this.$store.commit("setLoading", true);

    setTimeout(() => {
      const auth = getAuth();
      signInWithEmailAndPassword(auth, this.form.mail!, this.form.password!)
        .then(() => {
          this.validAuth = true;
          this.$store.commit("setLoading", false);
        })
        .catch((error) => {
          process.env.NODE_ENV == "development" && console.log(error);
          this.sending = false;
          this.$store.commit("setLoading", false);
          this.invalidAuth = true;
        });
    }, 1000);
  }

  validateUser() {
    /* Set all elements to "touched"
        Trigger display of validation messages  */
    this.$v.$touch();

    if (!this.$v.$invalid) {
      this.login();
    }
  }

  async redirectToSheetsPage() {
    this.$router.push({ name: "Sheets" });
  }

  get mailErrors() {
    const errors: any[] = [];
    if (!this.$v.form.mail!.$dirty) return errors;
    !this.$v.form.mail!.email &&
      errors.push("Email needs to be a valid Email address!");
    !this.$v.form.mail!.required && errors.push("Email is required!");
    return errors;
  }

  get passwordErrors() {
    const errors: any[] = [];
    if (!this.$v.form.password!.$dirty) return errors;
    !this.$v.form.password!.required && errors.push("Password is required!");
    return errors;
  }
}
