





















































import Vue from "vue";
import Component from "vue-class-component";
import { email, required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import { EmailAuthProvider } from "@firebase/auth";

@Component({
  mixins: [validationMixin],
  validations: {
    form: {
      mail: {
        email,
        required,
      },
      password: {
        required,
      },
    },
  },
})
export default class ChangeEmail extends Vue {
  form = {
    password: "",
    mail: "",
  };

  showPassword = false;
  showReauthenticateDialog = false;

  get mailErrors() {
    const errors: any[] = [];
    if (!this.$v.form.mail!.$dirty) return errors;
    !this.$v.form.mail!.required && errors.push("An Email is required!");
    !this.$v.form.mail!.email &&
      errors.push("Your input has to be a valid Email!");
    return errors;
  }

  get passwordErrors() {
    const errors: any[] = [];
    if (!this.$v.form.password!.$dirty) return errors;
    !this.$v.form.password!.required && errors.push("Password is required!");
    return errors;
  }

  validateMail() {
    this.$v.form.mail!.$touch();

    if (!this.$v.form.mail!.$invalid) {
      this.showReauthenticateDialog = true;
    }
  }

  async changeMail() {
    if (!this.$v.$invalid) {
      try {
        this.$store.commit("setLoading", true);

        await this.$store.dispatch(
          "reauthenticateUser",
          EmailAuthProvider.credential(
            this.$store.getters.getUser.email,
            this.form.password
          )
        );
        await this.$store.dispatch("changeEmail", this.form.mail);
        this.$store.commit("setLoading", false);
      } catch (error) {
        console.log(error);
      }
    }
  }
}
