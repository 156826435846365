










import Vue from "vue";
import Component from "vue-class-component";
import Picker from "@/components/Shared/Picker/Picker.vue";

@Component({
  components: {
    Picker,
  },
})
export default class Sheets extends Vue {
  cardClicked(sheet: any) {
    this.$router.push({ path: `/sheets/${sheet.$id}` });
  }

  async created() {
  }
}
