<template>
  <settings-panel></settings-panel>
</template>

<script>
import Vue from "vue";
import Component from "vue-class-component";
import SettingsPanel from "@/components/Settings/SettingsPanel.vue";

@Component({
  components: {
    SettingsPanel,
  },
})
export default class Settings extends Vue {}
</script>

<style>
</style>