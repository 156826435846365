import { RootState } from "@/types/RootState";
import { ImportSplit, VariableSheet } from "@/types/Sheets";
import { collection, getDoc, updateDoc } from "firebase/firestore";
import { query, where, getDocs, doc, deleteDoc } from "firebase/firestore";
import { ActionContext } from "vuex";
import _ from "lodash-es"

type TemplateModuleState = {
    templates: Array<VariableSheet>,
    importSplits: Array<ImportSplit>
}

const templatesModule = {
    state: (): TemplateModuleState => ({
        templates: [],
        importSplits: []
    }),
    getters: {
        getTemplateById: (state: TemplateModuleState) => (
            templateId: string,
        ) => {
            return state.templates.find(
                (t) => t.id == templateId
            );
        },
        getTemplatesByType: (state: TemplateModuleState) => (
            sheetType: "positional" | "preflop",
        ) => {
            return state.templates.filter(
                (template) => template.type === sheetType
            );
        },
        getTemplateByLabel: (state: TemplateModuleState) => (label: string) => {
            return state.templates.find((template) => template.label === label);
        },
        getSplitById: (state: TemplateModuleState) => (
            splitId: string,
        ) => {
            return state.importSplits.find(
                (split) => split.id == splitId
            );
        },
        getSplits: (state: TemplateModuleState) => {
            return state.importSplits
        },
    },
    actions: {
        async setTemplateState(
            context: ActionContext<TemplateModuleState, RootState>,
        ) {
            const templateTypes = ["positional", "preflop"]
            const db = context.rootState.firestore
            const uid = context.getters.getUser.uid

            if (context.getters.isAdmin) {
                const splitsRef = collection(db, "importSplits")
                const q = query(splitsRef, where("write", "array-contains", uid));
                const q2 = query(splitsRef, where("read", "array-contains", uid));

                const querySnapshot = await getDocs(q)
                const querySnapshot2 = await getDocs(q2)

                const allSplits = [...querySnapshot.docs, ...querySnapshot2.docs].flatMap((split) => { return { id: split.id, ...split.data() } })

                context.commit("addSplits", _.uniqBy(allSplits, 'id'))
            } else {
                const splitsRef = collection(db, "importSplits")
                const teamsBatch = context.getters.getTeams.flatMap(async (team: { id: string; }) => {
                    const q = query(splitsRef, where("read", "array-contains", `team:${team.id}`));
                    const querySnapshot = await getDocs(q);
                    return querySnapshot.docs.flatMap((team) => { return { id: team.id, ...team.data() } })
                })
                const splits = (await Promise.all(teamsBatch)).flat()
                context.commit("addSplits", _.uniqBy(splits, 'id'))
            }

            const templatesBatch = templateTypes.flatMap(async (type) => {
                const templatesRef = collection(db, "templates");

                if (context.getters.isAdmin) {
                    const q = query(templatesRef, where("type", "==", type), where("write", "array-contains", uid));
                    const querySnapshot = await getDocs(q);
                    const q2 = query(templatesRef, where("type", "==", type), where("read", "array-contains", uid));
                    const querySnapshot2 = await getDocs(q2)

                    const allTemplates = [...querySnapshot.docs, ...querySnapshot2.docs].flatMap((template) => { return { id: template.id, ...template.data() } })
                    return _.uniqBy(allTemplates, 'id');
                } else {
                    const teamsBatch = context.getters.getTeams.flatMap(async (team: { id: string; }) => {
                        const q = query(templatesRef, where("read", "array-contains", `team:${team.id}`));
                        const querySnapshot = await getDocs(q);
                        return querySnapshot.docs.flatMap((team) => { return { id: team.id, ...team.data() } })
                    })
                    return (await Promise.all(teamsBatch)).flat()
                }
            })
            const templatesArr = (await Promise.all(templatesBatch)).flat()
            context.commit("addTemplates", templatesArr)
        },
        async persistTemplate(context: ActionContext<TemplateModuleState, RootState>, payload: VariableSheet) {
            const db = context.rootState.firestore

            const templateRef = doc(db, "templates", payload.id!)
            await updateDoc(templateRef, payload);
            context.commit("updateTemplate", payload)
        },
        async persistSplit(context: ActionContext<TemplateModuleState, RootState>, payload: ImportSplit) {
            const db = context.rootState.firestore

            const templateRef = doc(db, "importSplits", payload.id!)
            await updateDoc(templateRef, payload);
            context.commit("updateSplit", payload)
        },
        async deleteTemplate(context: ActionContext<TemplateModuleState, RootState>, payload: VariableSheet) {
            const db = context.rootState.firestore

            const templateRef = doc(db, "templates", payload.id!)
            await deleteDoc(templateRef);
            context.commit("removeTemplate", payload)
        },
        async deleteTemplateSplit(context: ActionContext<TemplateModuleState, RootState>, payload: ImportSplit) {
            const db = context.rootState.firestore
            for await (const t of payload.splitTemplates) {
                const templateRef = doc(db, "templates", t)
                await deleteDoc(templateRef);
                context.commit("removeTemplate", { id: t })
            }
            const splitRef = doc(db, "importSplits", payload.id!)
            await deleteDoc(splitRef);
            context.commit("removeSplit", payload)
        },
    },
    mutations: {
        addTemplate(state: TemplateModuleState, payload: VariableSheet) {
            state.templates.push(payload)
        },
        addTemplates(state: TemplateModuleState, payload: Array<VariableSheet>) {
            state.templates.push(...payload);
        },
        addSplit(state: TemplateModuleState, split: ImportSplit) {
            state.importSplits.push(split);
        },
        addSplits(state: TemplateModuleState, payload: Array<ImportSplit>) {
            state.importSplits.push(...payload);
        },
        updateSplit(state: TemplateModuleState, payload: ImportSplit) {
            state.importSplits = state.importSplits.map((split) => {
                if (split.id === payload.id) {
                    return payload
                }
                return split
            });
        },
        updateTemplate(state: TemplateModuleState, payload: VariableSheet) {
            state.templates = state.templates.map((template) => {
                if (template.id === payload.id) {
                    return payload
                }
                return template
            });
        },
        removeTemplate(state: TemplateModuleState, payload: VariableSheet) {
            state.templates = state.templates.filter(
                (template) => template.id !== payload.id
            );
        },
        removeSplit(state: TemplateModuleState, payload: ImportSplit) {
            state.importSplits = state.importSplits.filter(
                (split) => split.id !== payload.id
            );
        },
        clearTemplates(state: TemplateModuleState) {
            state.templates = []
        },
    },
}

export { templatesModule }