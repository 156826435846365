



























import { Component, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {},
})
export default class FeedbackReader extends Vue {
  feedbackAvailable: boolean = false;
  feedbackIndex: number = 1;
  content: any = { ops: [] };
  author: string = "";

  config = {
    readOnly: true,
  };

  @Watch("feedbackIndex")
  onFeedbackIndexChanged() {
    this.setContent();
  }

  get feedback() {
    const feedback = this.$store.getters.getFeedback;
    if (feedback.length) {
      this.feedbackAvailable = true;
    }
    return feedback;
  }

  get selectedFeedback() {
    return this.feedback[this.feedbackIndex - 1];
  }

  setContent() {
    // @ts-ignore
    this.$refs.feedbackReaderQuill?.editor.setContents(
      this.selectedFeedback?.ops
    );
  }
}
