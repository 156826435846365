




import { Component, Vue } from "vue-property-decorator";
import ForgotPasswordComponent from "@/components/ForgotPassword/ForgotPasswordComponent.vue";

@Component({
  components: {
    ForgotPasswordComponent,
  },
  name: "ForgotPassword",
})
export default class ForgotPassword extends Vue {}
