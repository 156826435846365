const microRows = [
    {
        position: "sb",
        vpip: 20,
        pfr: 14,
    },
    {
        position: "bb",
        vpip: 20,
        pfr: 14,
    },
]

const microThresholds = [
    {
        position: "sb",
        thresholds: [
            {
                category: "vpip",
                inner_top: 20,
                inner_bottom: 12,
                slight_top: 22,
                slight_bottom: 10,
                medium_top: 24,
                medium_bottom: 8,
            },
            {
                category: "pfr",
                inner_top: 20,
                inner_bottom: 12,
                slight_top: 22,
                slight_bottom: 10,
                medium_top: 24,
                medium_bottom: 8,
            },
        ],
    },
    {
        position: "bb",
        thresholds: [
            {
                category: "vpip",
                inner_top: 20,
                inner_bottom: 12,
                slight_top: 22,
                slight_bottom: 10,
                medium_top: 24,
                medium_bottom: 8,
            },
            {
                category: "pfr",
                inner_top: 20,
                inner_bottom: 12,
                slight_top: 22,
                slight_bottom: 10,
                medium_top: 24,
                medium_bottom: 8,
            },
        ],
    },
]

const positionalObj = {
    type: "positional",
    label: "My First Positional Analysis",
    rows: microRows,
    thresholds: microThresholds
}

const preflopObj = {
    type: "preflop",
    label: "My First Preflop Analysis",
    rows: microRows,
    thresholds: microThresholds
}

export { preflopObj, positionalObj }