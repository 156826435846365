












































































































import Vue from "vue";
import Component from "vue-class-component";
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";

@Component({
  components: {},
  mixins: [validationMixin],
  validations: {
    form: {
      userMail: {
        required,
        email,
      },
    },
  },
})
export default class UserManagement extends Vue {
  form = { userMail: null };
  selectedTeamIndex: any = null;
  showAddUserDialog = false;
  showInviteStatusSnackbar = false;
  inviteStatusMessage = "";
  inviteStatusError = "error";
  sending = false;

  headers = [
    { text: "Email", value: "email" },
    { text: "Remove User", value: "remove", sortable: false, align: "center" },
  ];

  get teams() {
    return this.$store.getters.getTeams;
  }

  get members() {
    const members = this.selectedTeam.membership?.members;
    if (members.length > 1)
      return members.filter((membership: { email: string; id: string }) => {
        if (membership.id !== this.$store.getters.getUser.uid)
          return {
            email: membership.email,
            id: membership.id,
          };
        else return;
      });
    return undefined;
  }

  get selectedTeam() {
    return this.teams[this.selectedTeamIndex];
  }

  get userMailErrors() {
    const errors: any[] = [];
    if (!this.$v.form.userMail!.$dirty) return errors;
    !this.$v.form.userMail!.email &&
      errors.push("User Email needs to be a valid Email address!");
    !this.$v.form.userMail!.required && errors.push("User Email is required!");
    return errors;
  }

  async validateUserMail() {
    this.$v.$touch();

    if (!this.$v.$invalid) {
      this.sending = true;
      this.$store.commit("setLoading", true);
      const response = await this.$store.dispatch("addUserToTeam", {
        mail: this.form.userMail,
        team: this.selectedTeam,
      });
      this.$store.commit("setLoading", false);
      this.sending = false;
      this.toggleInviteStatusSnackbar(
        response.data.error,
        response.data.message
      );

      this.$v.$reset();
      this.form.userMail = null;
    }
  }

  toggleInviteStatusSnackbar(error: boolean, message: string) {
    this.inviteStatusError = error ? "error" : "success";
    this.inviteStatusMessage = message;
    this.showInviteStatusSnackbar = true;
  }

  async exitAddUserDialog() {
    if (this.inviteStatusMessage != "") {
      this.$store.commit("clearTeams");
      this.$store.commit("setLoading", true);

      await this.$store.dispatch("queryTeams");

      this.$store.commit("setLoading", false);
    }
    this.showAddUserDialog = false;
  }

  async removeUser(user: any, team: string) {
    this.$store.commit("setLoading", true);
    await this.$store.dispatch("deleteUserFromTeam", {
      id: user.id,
      team: team,
    });
    this.$store.commit("setLoading", false);
  }
}
