var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('v-card-actions',[(this.selectedNote)?_c('h2',[_vm._v(" "+_vm._s(("Note #" + (this.selectedNoteIndex)))+" ")]):_vm._e(),[_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"my-2 mx-1",attrs:{"fab":"","elevation":"0","color":"success","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.createNote($event)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" add ")])],1)]}}])},[_c('span',[_vm._v("Add Note")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"my-2 mx-1",attrs:{"disabled":!(_vm.notes.length && _vm.selectedNote),"fab":"","elevation":"0","color":"error","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.deleteNote($event)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" delete ")])],1)]}}])},[_c('span',[_vm._v("Delete Note")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"my-2 ml-1",attrs:{"disabled":!(_vm.notes.length && _vm.selectedNote && _vm.selectedNote.ops.length),"outlined":"","fab":"","elevation":"0","small":""},on:{"click":_vm.saveNote}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" save ")])],1)]}}])},[_c('span',[_vm._v("Save Note")])])]],2),(_vm.selectedNote)?_c('quill',{ref:"notesQuill",staticClass:"pb-4",attrs:{"config":_vm.config},model:{value:(_vm.selectedNote.ops),callback:function ($$v) {_vm.$set(_vm.selectedNote, "ops", $$v)},expression:"selectedNote.ops"}}):_vm._e(),(_vm.notes.length)?_c('div',{staticClass:"text-center"},[_c('v-pagination',{attrs:{"total-visible":"5","length":_vm.notes.length},model:{value:(_vm.selectedNoteIndex),callback:function ($$v) {_vm.selectedNoteIndex=$$v},expression:"selectedNoteIndex"}})],1):_vm._e(),(!_vm.notes.length)?_c('div',{staticClass:"text-center text-subtitle-2 ma-6"},[_c('span',[_vm._v(" You have not created any Notes yet. ")])]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }