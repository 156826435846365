<template>
  <v-container fluid>
    <v-row justify="center">
      <div style="background: rgba(0, 0, 0, 0.2)" class="py-2 px-4 rounded">
        <v-img
          :max-width="this.$vuetify.breakpoint.mobile ? '50vw' : '20vw'"
          src="@/assets/logo2.png"
        >
        </v-img>
      </div>
    </v-row>
    <v-row align="center" justify="center" v-if="!$store.getters.getUser">
      <v-col sm="10" md="8">
        <v-sheet
          class="transparent"
          style="display: flex; flex-direction: column"
        >
          <v-btn large class="ma-2 landingBtn" outlined :to="{ name: 'Login' }">
            Login
          </v-btn>
          <span class="text-caption ml-2"
            >Note: This service is invite-only. For more information contact
            <a href="mailto:dennis@reinkober.it">dennis@reinkober.it</a></span
          >
        </v-sheet>
      </v-col>
    </v-row>
    <v-row
      class="mt-8"
      align="start"
      justify="center"
      v-if="$store.getters.getUser"
    >
      <v-col cols="12" md="4">
        <v-card
          elevation="0"
          style="
            background: url('/notesbg.png');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
          "
        >
          <div
            style="
              background: linear-gradient(to top, transparent, black);
              height: 100%;
              width: 100%;
              display: flex;
              flex-direction: column;
              justify-content: flex-end;
            "
          >
            <v-card-title style="color: white">Notes</v-card-title>
            <v-card-subtitle style="color: white"
              >Track Your Progress</v-card-subtitle
            >
            <v-card-text style="font-weight: 500">
              Manage your notes and receive tailored feedback from your
              dedicated poker coach.
            </v-card-text>
          </div>

          <v-card-actions>
            <v-btn class="landingBtn" block outlined :to="{ name: 'Notes' }"
              >Explore <v-icon right dark>open_in_new</v-icon></v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>

      <v-col cols="12" md="4">
        <v-card
          elevation="0"
          style="
            background: url('/sheetsbg.png');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
          "
        >
          <div
            style="
              background: linear-gradient(to top, transparent, black);
              height: 100%;
              width: 100%;
              display: flex;
              flex-direction: column;
              justify-content: flex-end;
            "
          >
            <v-card-title>Sheets</v-card-title>
            <v-card-subtitle>Organize Your Strategies</v-card-subtitle>
            <v-card-text style="font-weight: 500"
              >Manage and import your sheets directly from PokerTracker via CSV
              for a seamless experience.</v-card-text
            >
          </div>
          <v-card-actions>
            <v-btn class="landingBtn" block outlined :to="{ name: 'Sheets' }"
              >Explore <v-icon right dark>open_in_new</v-icon></v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>

      <v-col cols="12" md="4">
        <v-card
          elevation="0"
          style="
            background: url('/gallerybg.png');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
          "
        >
          <div
            style="
              background: linear-gradient(to top, transparent, black);
              height: 100%;
              width: 100%;
              display: flex;
              flex-direction: column;
              justify-content: flex-end;
              text-shadow: 4px 4px 4px rgba(0, 0, 0, 1);
            "
          >
            <v-card-title>Gallery</v-card-title>
            <v-card-subtitle>Capture Your Highlights</v-card-subtitle>
            <v-card-text style="font-weight: 500"
              >Store and manage your screenshots or any important images
              relevant to your poker journey.</v-card-text
            >
          </div>
          <v-card-actions>
            <v-btn class="landingBtn" block outlined :to="{ name: 'Graphs' }"
              >Explore <v-icon right dark>open_in_new</v-icon></v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row
      v-if="this.$store.getters.isAdmin"
      class=""
      align="start"
      justify="center"
    >
      <v-col cols="12" sm="6">
        <v-card
          elevation="0"
          style="
            background: url('/adminbg.png');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
          "
        >
          <div
            style="
              background: linear-gradient(to top, transparent, black);
              height: 100%;
              width: 100%;
              display: flex;
              flex-direction: column;
              justify-content: flex-end;
            "
          >
            <v-card-title>Admin</v-card-title>
            <v-card-subtitle>Manage Your Team</v-card-subtitle>
            <v-card-text style="font-weight: 500"
              >Take control of your teams and provide valuable feedback for
              better performance.</v-card-text
            >
          </div>
          <v-card-actions>
            <v-btn class="landingBtn" block outlined :to="{ name: 'Admin' }"
              >Explore <v-icon right dark>open_in_new</v-icon></v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>

      <v-col cols="12" sm="6">
        <v-card
          elevation="0"
          style="
            background: url('/templatesbg.png');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
          "
        >
          <div
            style="
              background: linear-gradient(to top, transparent, black);
              height: 100%;
              width: 100%;
              display: flex;
              flex-direction: column;
              justify-content: flex-end;
            "
          >
            <v-card-title>Templates</v-card-title>
            <v-card-subtitle>Automate Your Workflow</v-card-subtitle>
            <v-card-text style="font-weight: 500"
              >Manage your templates and effortlessly import splits to
              streamline your processes.</v-card-text
            >
          </div>
          <v-card-actions>
            <v-btn class="landingBtn" block outlined :to="{ name: 'Templates' }"
              >Explore <v-icon right dark>open_in_new</v-icon></v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {};
</script>

<style scoped>
.landingBtn {
  background-color: #121212;
}
</style>
