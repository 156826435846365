



















































































































































import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { Component, Prop, Vue } from "vue-property-decorator";
import GraphPreview from "@/components/Graphs/SingleView/GraphPreview.vue";
// eslint-disable-next-line no-unused-vars
import { Graph } from "@/types/Graphs";

@Component({
  components: {
    GraphPreview,
  },
  mixins: [validationMixin],
  validations: {
    form: {
      title: {
        required,
      },
    },
  },
})
export default class GraphSingleView extends Vue {
  @Prop() graph!: Graph;
  showPreview = false;
  showEdit = false;
  newAssignedDate = "";
  errorOnUpdate = false;
  successfulUpdate = false;

  form = {
    title: "",
  };

  created() {
    this.newAssignedDate = this.graph.date;
    this.form.title = this.graph.label;
  }

  dayDate(date: number) {
    const transformedDate = new Date(Number(date)).toLocaleDateString();
    return `${transformedDate}`;
  }

  get titleErrors() {
    const errors: any = [];
    if (!this.$v.form.title!.$dirty) return errors;
    !this.$v.form.title!.required && errors.push("Title is required.");
    return errors;
  }

  submitForm() {
    this.$v.$touch();

    if (!this.$v.$invalid) {
      this.updateGraph();
    }
  }

  async updateGraph() {
    try {
      this.$store.commit("setLoading", true);

      await this.$store.dispatch("persistGraph", {
        ...this.graph,
        label: this.form.title,
        date: this.newAssignedDate,
      });
      this.successfulUpdate = true;
      this.$store.commit("setLoading", false);
    } catch (error) {
      process.env.NODE_ENV == "development" && console.log(error);

      this.errorOnUpdate = true;
    }
  }

  monthDate(date: any) {
    var monthName = new Intl.DateTimeFormat("en-US", { month: "long" }).format;
    return `${monthName(new Date(date))} ${new Date(date).getFullYear()}`;
  }

  async deleteImage() {
    this.$store.commit("setLoading", true);

    await this.$store.dispatch("deleteGraph", this.graph);
    this.$store.commit("setLoading", false);
  }
}
