


































import { Prop, Component, Vue } from "vue-property-decorator";
import EditMicroThresholds from "@/components/Templates/ContextMenu/Dialog/Micro/Thresholds/EditMicroThresholds.vue";
// eslint-disable-next-line no-unused-vars
import { VariableSheet } from "@/types/Sheets";
import _ from "lodash-es";

@Component({
  components: {
    EditMicroThresholds,
  },
})
export default class MicroThresholds extends Vue {
  @Prop() template!: VariableSheet;

  /* Returns array of thresholds */
  get categories(): string[] {
    let arr: string[] = [];

    if (this.template.thresholds.length > 0) {
      this.template.thresholds.forEach((thresholdObj: any) =>
        thresholdObj.thresholds.forEach((el: any) => {
          arr.push(el.category!);
        })
      );
    } else return [];

    return [...new Set(arr)];
  }

  get categoryGroups() {
    return _.chunk(this.categories, 2);
  }

  get positions(): string[] {
    let arr: string[] = [];

    if (this.template.thresholds.length > 0) {
      this.template.thresholds.forEach((thresholdObj: any) =>
        arr.push(thresholdObj.position)
      );
    } else return [];

    return [...new Set(arr)];
  }
}
