
















// eslint-disable-next-line no-unused-vars
import { VariableSheet } from "@/types/Sheets";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class DialogGeneral extends Vue {
  @Prop() template!: VariableSheet;
}
