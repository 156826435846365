




















































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { required, decimal } from "vuelidate/lib/validators";
import * as sheetColors from "@/store/util/sheetColors";
import { validationMixin } from "vuelidate";
// eslint-disable-next-line no-unused-vars
import { Threshold, VariableSheet } from "@/types/Sheets";

@Component({
  mixins: [validationMixin],
  validations: {
    form: {
      innerBottomThreshold: {
        required,
        decimal,
      },
      innerTopThreshold: {
        required,
        decimal,
      },
      slightTopThreshold: {
        required,
        decimal,
      },
      slightBottomThreshold: {
        required,
        decimal,
      },
      mediumTopThreshold: {
        required,
        decimal,
      },
      mediumBottomThreshold: {
        required,
        decimal,
      },
    },
  },
})
export default class EditMacroThresholds extends Vue {
  @Prop() template!: VariableSheet;
  @Prop() category!: string;

  sheetColors = sheetColors;

  get usedThreshold() {
    return this.template?.thresholds.find(
      (threshold: any) => threshold.category === this.category
    );
  }

  // When Sheet changes (another Sheet is selected), refresh the form data
  @Watch("template")
  onTemplateChanged() {
    const newThreshold = this.template.thresholds.find(
      (threshold: any) => threshold.category === this.category
    );
    const newForm = {
      innerBottomThreshold: newThreshold?.inner_bottom,
      innerTopThreshold: newThreshold?.inner_top,
      slightBottomThreshold: newThreshold?.slight_bottom,
      slightTopThreshold: newThreshold?.slight_top,
      mediumBottomThreshold: newThreshold?.medium_bottom,
      mediumTopThreshold: newThreshold?.medium_top,
    };

    Vue.set(this, "form", newForm);
  }

  created() {
    this.form = {
      innerBottomThreshold: this.usedThreshold?.inner_bottom,
      innerTopThreshold: this.usedThreshold?.inner_top,
      slightBottomThreshold: this.usedThreshold?.slight_bottom,
      slightTopThreshold: this.usedThreshold?.slight_top,
      mediumBottomThreshold: this.usedThreshold?.medium_bottom,
      mediumTopThreshold: this.usedThreshold?.medium_top,
    };
  }

  form = {
    innerBottomThreshold: this.usedThreshold?.inner_bottom,
    innerTopThreshold: this.usedThreshold?.inner_top,
    slightBottomThreshold: this.usedThreshold?.slight_bottom,
    slightTopThreshold: this.usedThreshold?.slight_top,
    mediumBottomThreshold: this.usedThreshold?.medium_bottom,
    mediumTopThreshold: this.usedThreshold?.medium_top,
  };

  validateSetDeviation() {
    /* Set all elements to "touched"
        Trigger display of validation messages  */
    this.$v.$touch();

    if (!this.$v.$invalid) {
      this.changeDeviation();
    }
  }

  async changeDeviation() {
    this.$store.commit("setLoading", true);

    let updatedThresholds: Threshold[] = [];

    /* Copy Sheet Thresholds to empty object for reactivity reasons */
    Object.assign(updatedThresholds, this.template.thresholds);

    /* Change values of threshhold that has same category as this form (basically update the thresholds for store update) */
    updatedThresholds.forEach((t: Threshold) => {
      if (t.category == this.category) {
        t.inner_top = this.form.innerTopThreshold!;
        t.inner_bottom = this.form.innerBottomThreshold!;
        t.slight_top = this.form.slightTopThreshold!;
        t.slight_bottom = this.form.slightBottomThreshold!;
        t.medium_top = this.form.mediumTopThreshold!;
        t.medium_bottom = this.form.mediumBottomThreshold!;
      }
    });

    let updatedTemplate: VariableSheet = this.template;
    updatedTemplate.thresholds = updatedThresholds;
    try {
      await this.$store.dispatch("persistTemplate", updatedTemplate);
      this.$store.commit("setLoading", false);
    } catch (error) {
      this.$store.commit("setLoading", false);

      console.log(error);
    }
  }

  get mediumTopThresholdErrors() {
    const errors: any[] = [];
    if (!this.$v.form.mediumTopThreshold!.$dirty) return errors;
    !this.$v.form.mediumTopThreshold!.decimal &&
      errors.push("Medium Top Threshold needs to be a number!");
    !this.$v.form.mediumTopThreshold!.required &&
      errors.push("Medium Top Threshold is required!");
    !(
      this.$v.form.mediumTopThreshold!.$model >
      this.$v.form.slightTopThreshold!.$model
    ) && errors.push("This Threshold needs to be bigger than the one below.");
    return errors;
  }

  get mediumBottomThresholdErrors() {
    const errors: any[] = [];
    if (!this.$v.form.mediumBottomThreshold!.$dirty) return errors;
    !this.$v.form.mediumBottomThreshold!.decimal &&
      errors.push("Medium Bottom Threshold needs to be a number!");
    !this.$v.form.mediumBottomThreshold!.required &&
      errors.push("Medium Bottom Threshold is required!");
    !(
      this.$v.form.slightBottomThreshold!.$model >
      this.$v.form.mediumBottomThreshold!.$model
    ) && errors.push("This Threshold needs to be smaller than the one above.");
    return errors;
  }

  get slightTopThresholdErrors() {
    const errors: any[] = [];
    if (!this.$v.form.slightTopThreshold!.$dirty) return errors;
    !this.$v.form.slightTopThreshold!.decimal &&
      errors.push("Slight Top Threshold needs to be a number!");
    !this.$v.form.slightTopThreshold!.required &&
      errors.push("Slight Top Threshold is required!");
    !(
      this.$v.form.slightTopThreshold!.$model >
      this.$v.form.innerTopThreshold!.$model
    ) && errors.push("This Threshold needs to be bigger than the one below.");
    !(
      this.$v.form.mediumTopThreshold!.$model >
      this.$v.form.slightTopThreshold!.$model
    ) && errors.push("This Threshold needs to be smaller than the one above.");
    return errors;
  }

  get slightBottomThresholdErrors() {
    const errors: any[] = [];
    if (!this.$v.form.slightBottomThreshold!.$dirty) return errors;
    !this.$v.form.slightBottomThreshold!.decimal &&
      errors.push("Slight Bottom Threshold needs to be a number!");
    !this.$v.form.slightBottomThreshold!.required &&
      errors.push("Slight Bottom Threshold is required!");
    !(
      this.$v.form.slightBottomThreshold!.$model >
      this.$v.form.mediumBottomThreshold!.$model
    ) && errors.push("This Threshold needs to be bigger than the one below.");
    !(
      this.$v.form.innerBottomThreshold!.$model >
      this.$v.form.slightBottomThreshold!.$model
    ) && errors.push("This Threshold needs to be smaller than the one above.");
    return errors;
  }

  get innerBottomThresholdErrors() {
    const errors: any[] = [];
    if (!this.$v.form.innerBottomThreshold!.$dirty) return errors;
    !this.$v.form.innerBottomThreshold!.decimal &&
      errors.push("Inner Bottom Threshold needs to be a number!");
    !this.$v.form.innerBottomThreshold!.required &&
      errors.push("Inner Bottom Threshold is required!");
    !(
      this.$v.form.innerBottomThreshold!.$model >
      this.$v.form.slightBottomThreshold!.$model
    ) && errors.push("This Threshold needs to be bigger than the one below.");
    !(
      this.$v.form.innerTopThreshold!.$model >
      this.$v.form.innerBottomThreshold!.$model
    ) && errors.push("This Threshold needs to be smaller than the one above.");
    return errors;
  }

  get innerTopThresholdErrors() {
    const errors: any[] = [];
    if (!this.$v.form.innerTopThreshold!.$dirty) return errors;
    !this.$v.form.innerTopThreshold!.decimal &&
      errors.push("Inner Top Threshold needs to be a number!");
    !this.$v.form.innerTopThreshold!.required &&
      errors.push("Inner Top Threshold is required!");
    !(
      this.$v.form.innerTopThreshold!.$model >
      this.$v.form.innerBottomThreshold!.$model
    ) && errors.push("This Threshold needs to be bigger than the one below.");
    !(
      this.$v.form.slightTopThreshold!.$model >
      this.$v.form.innerTopThreshold!.$model
    ) && errors.push("This Threshold needs to be smaller than the one above.");
    return errors;
  }
}
