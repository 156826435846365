





































































import { ImportSplit, VariableSheet } from "@/types/Sheets";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class SplitDisplay extends Vue {
  @Prop() showSplitDisplay!: boolean;
  @Prop() relatedSplit!: ImportSplit | null;

  @Prop() template!: VariableSheet | null;
  @Prop() sheet!: VariableSheet | null;

  headers = [
    { text: "Team Name", value: "name" },
    {
      text: "Allow Usage of Template",
      value: "permission",
      sortable: false,
    },
  ];

  async addPermission(item: any) {
    const permissionsReadSplit = [
      this.relatedSplit?.read,
      `team:${item.id}`,
    ].flat();
    this.$store.commit("setLoading", true);

    await this.$store.dispatch("persistSplit", {
      ...this.relatedSplit,
      read: permissionsReadSplit,
    });

    for await (const tId of this.relatedSplit?.splitTemplates!) {
      const template = this.$store.getters.getTemplateById(tId);

      const permissionsReadTemplate = [template.read, `team:${item.id}`].flat();

      await this.$store.dispatch("persistTemplate", {
        ...template,
        read: permissionsReadTemplate,
      });
    }
    this.$store.commit("setLoading", false);
  }

  async removePermission(item: any) {
    this.$store.commit("setLoading", true);

    const permissionsReadSplit = this.relatedSplit?.read.filter(
      (permission) => permission !== `team:${item.id}`
    );
    await this.$store.dispatch("persistSplit", {
      ...this.relatedSplit,
      read: permissionsReadSplit,
    });

    for await (const tId of this.relatedSplit?.splitTemplates!) {
      const template = this.$store.getters.getTemplateById(tId);

      const permissionsReadTemplate = template.read.filter(
        (p: string) => p != `team:${item.id}`
      );

      await this.$store.dispatch("persistTemplate", {
        ...template,
        read: permissionsReadTemplate,
      });
    }
    this.$store.commit("setLoading", false);
  }

  get teams() {
    return this.$store.getters.getTeamPermissionsBySplit(this.relatedSplit);
  }

  get splitTemplates(): VariableSheet[] | undefined {
    return this.relatedSplit?.splitTemplates.map(
      (tId: string): VariableSheet => this.$store.getters.getTemplateById(tId)
    );
  }
}
