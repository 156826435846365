import { RootState } from "@/types/RootState";
import { AuthCredential, deleteUser, getAuth, reauthenticateWithCredential, sendPasswordResetEmail, updateEmail, User, UserCredential } from "firebase/auth";
import { email } from "vuelidate/lib/validators";
import { ActionContext } from "vuex";

type SessionModuleState = {
    user: User | null,
    queriedUser: boolean
}

const sessionModule = {
    state: (): SessionModuleState => ({
        user: null,
        queriedUser: false
    }),
    getters: {
        getUser: (state: SessionModuleState) => {
            return state.user
        },
        getQueriedUser: (state: SessionModuleState) => {
            return state.queriedUser
        },
    },
    actions: {
        async createPasswordReset(
            context: ActionContext<SessionModuleState, RootState>, payload: string
        ) {
            const auth = getAuth()
            await sendPasswordResetEmail(auth, payload)
        },
        async deleteAccount(context: ActionContext<SessionModuleState, RootState>) {
            const auth = getAuth();
            const user = auth.currentUser;

            return deleteUser(user!)
        },
        async changeEmail(context: ActionContext<SessionModuleState, RootState>, payload: string) {
            return updateEmail(context.getters.getUser, payload)
        },
        async reauthenticateUser(context: ActionContext<SessionModuleState, RootState>, payload: AuthCredential) {
            const auth = getAuth();
            const user = auth.currentUser;

            return reauthenticateWithCredential(user!, payload)
        }
    },
    mutations: {
        setUser(state: SessionModuleState, payload: User | null) {
            state.user = payload
        },
        setQueriedUser(state: SessionModuleState, payload: boolean) {
            state.queriedUser = payload
        },
    },
}

export { sessionModule }