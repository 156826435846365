












import Vue from "vue";
import Component from "vue-class-component";
import MacroSheet from "@/components/Shared/RangeSheets/MacroSheet.vue";
import Picker from "@/components/Shared/Picker/Picker.vue";

@Component({
  components: {
    MacroSheet,
    Picker,
  },
})
export default class Templates extends Vue {
}
