



























































import Vue from "vue";
import Component from "vue-class-component";
import MicroSheet from "@/components/Shared/RangeSheets/MicroSheet.vue";
import { parse } from "papaparse";
import { positionalRegex } from "@/util/regex";

@Component({
  components: {
    MicroSheet,
  },
})
export default class GlobalPopulation extends Vue {
  form = {
    positionalCSV: null,
    preflopCSV: null,
  };

  uploadPopulation = async () => {
    if (this.form.positionalCSV && this.form.preflopCSV) {
      /*  const positional = await this.parsePositional();
      const preflop = await this.parsePreflop(); */
    } else if (this.form.positionalCSV) {
      const positional = {
        ...(await this.parsePositional()),
        uploadedAt: new Date().getTime(),
      };
      await this.$store.dispatch("createDocument", {
        collection: "population",
        dataObj: positional,
      });
    } else if (this.form.preflopCSV) {
      const preflop = {
        ...(await this.parsePreflop()),
        uploadedAt: new Date().getTime(),
      };

      await this.$store.dispatch("createDocument", {
        collection: "population",
        dataObj: preflop,
      });
    }
  };

  parseCSV = async (file: File) => {
    return new Promise((resolve, reject) => {
      parse(file as any, {
        worker: true,
        skipEmptyLines: true,
        complete: async (results) => {
          results as { data: string[][]; errors: any[] };
          resolve(results.data);
        },
        error: (err) => {
          reject(err);
        },
      });
    });
  };

  parsePositional = async () => {
    const positional = (await this.parseCSV(
      this.form.positionalCSV!
    )) as string[][];

    const regex = positionalRegex.whole;
    const regexStat = positionalRegex.stat;
    const regexPosition = positionalRegex.position;
    const positions = [
      ...new Set(
        positional[0]
          .filter((t) => regex.test(t))
          .map((t) => {
            const position = regexPosition.exec(t)![0];
            return position.slice(2, position.length - 2);
          })
      ),
    ];

    const rows: any[] = positions.map((position) => {
      const row: any = {};
      row.position = position;

      positional[0].forEach((t, i) => {
        if (regex.test(t)) {
          const stat = regexStat.exec(t)![0];
          const statName = stat.slice(2, stat.length - 2);

          const _position = regexPosition.exec(t)![0];

          if (_position.slice(2, _position.length - 2) === position)
            row[statName] = positional[1][i];
        }
      });

      return row;
    });

    const thresholds = rows[0]
      ? Object.keys(rows[0])
          .filter((key) => key !== "position")
          .map((key) => {
            return {
              position: rows[0].position,
              thresholds: rows.map(() => {
                return {
                  category: key,
                };
              }),
            };
          })
      : [];

    const positionalPopulation = {
      label: "Positional Population #2",
      rows: rows,
      sheets: [],
      type: "positional",
      write: [],
      thresholds: thresholds,
      read: ["*"],
    };

    return positionalPopulation;
  };

  parsePreflop = async () => {
    const preflop = (await this.parseCSV(this.form.preflopCSV!)) as string[][];

    const categories = preflop[0].slice(1);
    const positions = [
      ...new Set(
        preflop.slice(1).map((csvRow) => {
          return csvRow[0];
        })
      ),
    ];

    const rows: any[] = positions.map((position) => {
      const row: any = {};
      row.position = position;
      categories.forEach((category, i) => {
        row[category] = preflop
          .slice(1)
          .filter((csvRow) => csvRow[0] === position)[0][i + 1];
      });

      return row;
    });

    const thresholds = positions.map((position) => {
      return {
        position: position,
        thresholds: categories.map((category) => {
          return {
            category: category,
          };
        }),
      };
    });

    const preflopPopulation = {
      label: "preflop Population #2",
      rows: rows,
      sheets: [],
      type: "preflop",
      write: [],
      thresholds: thresholds,
      read: ["*"],
    };

    return preflopPopulation;
  };
}
