























































































































































import { HotTable, HotColumn } from "@handsontable/vue";
import svgPartial from "@/components/Shared/RangeSheets/svgPartial.vue";
import Vue from "vue";
import { Prop, Component, Watch } from "vue-property-decorator";
import Handsontable from "handsontable";
// eslint-disable-next-line no-unused-vars
import { Threshold, VariableSheet } from "@/types/Sheets";
import sheetColors from "@/store/util/sheetColors";

@Component({
  components: {
    HotTable,
    HotColumn,
    svgPartial,
  },
})
export default class MacroSheet extends Vue {
  @Prop() sheet!: VariableSheet;
  @Prop() isTemplate!: boolean;

  hotData: any = [];
  showRangeSnackbar = false;
  selectedCellThreshold: Threshold | null = null;
  sheetColors = sheetColors;

  created() {
    this.setHotData();
  }

  setHotData() {
    Vue.set(this, "hotData", this.sheet.rows ? this.sheet.rows : []);
  }

  @Watch("sheet")
  onSheetChanged() {
    const a: any = this.$refs.hotTableComponent;

    this.setHotData();

    a.hotInstance.updateSettings(this.hotSettings);
    a.hotInstance.render();
  }

  get hotSettings() {
    return {
      contextMenu: {
        items: {
          row_above: {},
          row_below: {},
          remove_row: {},
        },
      },
      afterChange: async (changes: any) => {
        if (changes != null) {
          this.$store.commit("setLoading", true);
          this.isTemplate
            ? await this.$store.dispatch("persistTemplate", {
                ...this.sheet,
                rows: this.hotData,
              })
            : await this.$store.dispatch("persistSheet", {
                ...this.sheet,
                rows: this.hotData,
              });
          this.$store.commit("setLoading", false);
        }
      },
      afterSelection: (row: any, column: any) => {
        const prop = (
          this.$refs.hotTableComponent as any
        ).hotInstance.colToProp(column);
        if (prop != "label") {
          this.selectedCellThreshold = this.threshold(prop);

          this.showRangeSnackbar = true;
        } else {
          this.showRangeSnackbar = false;
          this.selectedCellThreshold = null;
        }
      },
      afterDeselect: () => {
        this.showRangeSnackbar = false;
        this.selectedCellThreshold = null;
      },
      renderer: this.cellRenderer,
      allowInsertColumn: true,
      autoRowSize: false,
      selectionMode: "single",
      autoColumnSize: false,
      data: this.hotData,
      columns: this.columns,
      colHeaders: this.colHeaders,
      licenseKey: "non-commercial-and-evaluation",
      manualColumnResize: true,
      stretchH: "all",
    };
  }

  cellRenderer(
    instance: any,
    td: HTMLTableCellElement,
    row: number,
    col: number,
    prop: string,
    value: any,
    // eslint-disable-next-line no-unused-vars
    cellProperties: any
  ) {
    let args = arguments as any;
    Handsontable.renderers.TextRenderer.apply(this, args);
    // if row contains negative number
    const t: Threshold = this.threshold(prop);
    const parsedVal: number = parseFloat(value);

    /* Inner Range */
    if (parsedVal <= t.inner_top! && parsedVal >= t.inner_bottom!) {
      td.style.background = sheetColors.innerTop;
      td.style.fontWeight = "600";
      return;
    }
    if (prop === "label") {
      td.className = "labelMacro";
    }

    /* Inner Top to Severe Top Range */
    if (parsedVal <= t.slight_top! && parsedVal >= t.inner_top!) {
      td.style.background = t.flipColors
        ? sheetColors.slightBottom
        : sheetColors.slightTop;
      td.style.fontWeight = "600";
      return;
    } else if (parsedVal <= t.medium_top! && parsedVal >= t.slight_top!) {
      td.style.background = t.flipColors
        ? sheetColors.mediumBottom
        : sheetColors.mediumTop;
      td.style.fontWeight = "600";
      return;
    } else if (parsedVal > t.medium_top) {
      td.style.background = t.flipColors
        ? sheetColors.severeBottom
        : sheetColors.severeTop;
      td.style.fontWeight = "600";
      return;
    }

    /* Inner Bottom to Severe Bottom Range */
    if (parsedVal <= t.inner_bottom! && parsedVal >= t.slight_bottom!) {
      td.style.background = t.flipColors
        ? sheetColors.slightTop
        : sheetColors.slightBottom;
      td.style.fontWeight = "600";
      return;
    } else if (parsedVal <= t.slight_bottom! && parsedVal >= t.medium_bottom!) {
      td.style.background = t.flipColors
        ? sheetColors.mediumTop
        : sheetColors.mediumBottom;
      td.style.fontWeight = "600";
      return;
    } else if (parsedVal < t.medium_bottom) {
      td.style.background = t.flipColors
        ? sheetColors.severeTop
        : sheetColors.severeBottom;
      td.style.fontWeight = "600";
      return;
    }
  }

  get columns() {
    let a: any[] = [];

    a.push({ data: "label" });

    this.categories.forEach((c) => a.push({ data: c }));
    return a;
  }

  get colHeaders() {
    let a: any[] = [];

    a.push("");

    this.categories.forEach((c) => a.push(c.toUpperCase()));
    return a;
  }

  /* Returns array of unique categories to know how many columns to render */
  get categories(): string[] {
    let categoryArr: string[] = [];

    this.sheet.thresholds.forEach((thresholdObj: any) => {
      categoryArr.push(thresholdObj.category!);
    });

    return categoryArr;
  }

  /* Returns a Range Object for a given category, used for range/color evaluation in the CustomRenderer */
  threshold(category: string): Threshold {
    let thresholdObj: any = {};

    this.sheet.thresholds.forEach((rowObj: any) => {
      if (rowObj.category === category) thresholdObj = rowObj;
    });

    return thresholdObj;
  }
}
