



























import NotesEditor from "@/components/Shared/NotesEditor.vue";
import Vue from "vue";
import Component from "vue-class-component";
import Coaching from "@/components/Admin/Coaching.vue";
import Teams from "@/components/Admin/Teams.vue";
import UserManagement from "@/components/Admin/UserManagement.vue";
import GlobalPopulation from "@/components/Admin/GlobalPopulation.vue";

@Component({
  components: {
    GlobalPopulation,
    NotesEditor,
    Teams,
    Coaching,
    UserManagement,
  },
})
export default class AdminPanel extends Vue {}
