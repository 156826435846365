




































import Vue from "vue";
import Component from "vue-class-component";
import MacroSheet from "@/components/Shared/RangeSheets/MacroSheet.vue";
import MicroSheet from "@/components/Shared/RangeSheets/MicroSheet.vue";
import TemplateContextMenu from "@/components/Templates/ContextMenu/TemplateContextMenu.vue";

@Component({
  components: {
    MacroSheet,
    MicroSheet,
    TemplateContextMenu,
  },
})
export default class TemplatesPanel extends Vue {
  showDeletedEmptySnackbar: boolean = false;
  showEditDialog: boolean = false;
  showDeleteDialog: boolean = false;

  get template() {
    return this.$store.getters.getTemplateById(this.$route.params.id);
  }

  async deleteTemplate() {
    this.$store.commit("setLoading", true);
    if (
      this.template.split &&
      this.$store.getters.getSplitById(this.template.split)
    ) {
      await this.$store.dispatch(
        "deleteTemplateSplit",
        this.$store.getters.getSplitById(this.template.split)
      );
    } else await this.$store.dispatch("deleteTemplate", this.template);
    this.$router.push({ name: "Templates" });
    this.$store.commit("setLoading", false);
  }

  async saveTemplate() {
    this.$store.commit("setLoading", true);

    if (this.template?.rows.length == 0 || this.template?.rows === undefined) {
      this.showDeletedEmptySnackbar = true;
    } else {
      try {
        await this.$store.dispatch("persistTemplate", this.template);
      } catch (error) {
        process.env.NODE_ENV == "development" && console.log(error);
      }
    }
    this.$store.commit("setLoading", false);
  }
}
