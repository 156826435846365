



















































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

@Component({
  mixins: [validationMixin],
  validations: {
    form: {
      graph: {
        required,
        isImage: (val) => {
          if (!val) return false;
          if (!/image\/\w{0}/g.test(val.type)) return false;
          return true;
        },
      },
      title: {
        required,
      },
    },
  },
})
export default class GraphUploadDialog extends Vue {
  @Prop() showUploadDialog!: boolean;

  selectedDate = null;
  invalidUpload = false;
  successfulUpload = false;

  form = {
    graph: null,
    title: null,
  };

  get fileErrors() {
    const errors: any = [];
    if (!this.$v.form.graph!.$dirty) return errors;
    !this.$v.form.graph!.required &&
      errors.push("A file is required for uploading.");
    !this.$v.form.graph!.isImage && errors.push("You can only upload images!");
    return errors;
  }

  get titleErrors() {
    const errors: any = [];
    if (!this.$v.form.title!.$dirty) return errors;
    !this.$v.form.title!.required && errors.push("Title is required.");
    return errors;
  }

  submitForm() {
    this.$v.$touch();

    if (!this.$v.$invalid) {
      if (this.selectedDate) this.upload();
      else this.invalidUpload = true;
    }
  }

  async upload() {
    this.$store.commit("setLoading", true);

    await this.$store.dispatch("createGraph", {
      directory: "graphs",
      file: this.form.graph,
      name: Date.now(),
      docName: this.form.title,
      date: this.selectedDate,
    });
    this.successfulUpload = true;
    this.$store.commit("setLoading", false);
  }
}
