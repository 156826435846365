var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"mb-4",attrs:{"min-width":"20rem"}},[_c('v-card-title',[_vm._v(_vm._s(_vm.template.label))]),_c('v-card-subtitle',[_vm._v("Context Menu")]),_c('v-card-actions',[_c('v-row',{staticClass:"mr-2 mb-2",attrs:{"no-gutters":"","align":"end","justify":"end"}},[_c('v-col',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"outlined":"","large":"","icon":""},on:{"click":_vm.saveTemplate}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("save")])],1)]}}])},[_c('span',[_vm._v("Save Template")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"info darken-2 mx-1",attrs:{"icon":"","large":""},on:{"click":function($event){$event.stopPropagation();_vm.showEditDialog = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" settings ")])],1)]}}])},[_c('span',[_vm._v("Template Settings")])]),_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},model:{value:(_vm.showEditDialog),callback:function ($$v) {_vm.showEditDialog=$$v},expression:"showEditDialog"}},[_c('v-card',[_c('v-toolbar',[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.showEditDialog = false}}},[_c('v-icon',[_vm._v("close")])],1),_c('v-toolbar-title',[_vm._v(" Template Options ")])],1),_c('dialog-general',{attrs:{"template":_vm.template}}),_c('v-container',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',{staticClass:"text-h4 ml-n6"},[_vm._v("Permissions")]),_c('v-card-subtitle',{staticClass:"ml-n6 mt-n4"},[_vm._v(" Set Permissions to allow Usage of your Templates for Coaching Teams and Everyone Else ")]),(this.relatedSplit)?_c('p',{staticClass:"text-caption text-center mb-2 mt-6"},[_vm._v(" Since this Template belongs to an Import Split, you need to change its Permissions in the Split Menu "),_c('br'),_vm._v(" (Open it by clicking "),_c('b',{staticClass:"accent--text"},[_vm._v(_vm._s(_vm.relatedSplit && _vm.relatedSplit.name))]),_vm._v(" in the Context Menu Card) ")]):_vm._e(),(!this.relatedSplit)?_c('v-data-table',{staticClass:"mx-4 mt-6",attrs:{"headers":_vm.headers,"items":_vm.teams,"no-data-text":"No Teams to display"},scopedSlots:_vm._u([{key:"item.permission",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{attrs:{"color":"success","input-value":item.permission},on:{"click":function($event){item.permission
                            ? _vm.removePermission(item)
                            : _vm.addPermission(item)}}})]}}],null,false,273840432)}):_vm._e()],1)],1),(_vm.isMicroSheetTemplate)?[_c('micro-columns',{attrs:{"template":_vm.template}}),_c('micro-thresholds',{attrs:{"template":_vm.template}})]:_vm._e(),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{on:{"click":function($event){_vm.showEditDialog = false}}},[_vm._v(" Close ")]),_c('v-btn',{staticClass:"success--text",on:{"click":_vm.saveTemplate}},[_vm._v(" Save Changes ")])],1)],2)],1),(_vm.relatedSplit && _vm.relatedSplit.name)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
                            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"accent darken-3 mx-1",attrs:{"icon":"","large":""},on:{"click":function($event){_vm.showSplitDisplay = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" call_split ")])],1)]}}],null,false,2592919290)},[_c('span',[_vm._v("Show Split")])]):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
                            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"red darken-3 mx-1",attrs:{"icon":"","large":""},on:{"click":function($event){$event.stopPropagation();_vm.showDeleteDialog = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" delete ")])],1)]}}])},[_c('span',[_vm._v("Delete Template")])]),_c('v-dialog',{attrs:{"transition":"dialog-bottom-transition","width":"500"},model:{value:(_vm.showDeleteDialog),callback:function ($$v) {_vm.showDeleteDialog=$$v},expression:"showDeleteDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Delete Template?")]),_c('v-card-text',[_vm._v(" Do you want to delete this Template? "),(_vm.relatedSplit)?_c('br'):_vm._e(),(_vm.relatedSplit)?_c('br'):_vm._e(),_vm._v(" "+_vm._s(_vm.relatedSplit && "Note: This will also delete its associated Import Split as well as its Templates.")+" ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{on:{"click":function($event){_vm.showDeleteDialog = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{staticClass:"red--text",on:{"click":function($event){return _vm.$emit('delete-template')}}},[_vm._v(" Yes ")])],1)],1)],1)],1)],1)],1)],1),(_vm.relatedSplit)?_c('split-display',{attrs:{"showSplitDisplay":_vm.showSplitDisplay,"relatedSplit":_vm.relatedSplit,"template":this.template},on:{"hide-split-display":function($event){_vm.showSplitDisplay = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }