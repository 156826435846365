

































































import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "ForgotPasswordComponent",
  mixins: [validationMixin],
  validations: {
    form: {
      mail: {
        required,
        email,
      },
    },
  },
})
export default class ForgotPasswordComponent extends Vue {
  form = {
    mail: null,
  };

  failedSend = false;
  validSend = false;
  sending = false;

  clearForm() {
    this.$v.$reset();
    this.form.mail = null;
  }

  async sendPasswordMail() {
    this.sending = true;
    this.$store.commit("setLoading", true);

    try {
      this.$store.commit("setLoading", true);
      await this.$store.dispatch("createPasswordReset", this.form.mail);
      this.$store.commit("setLoading", false);

      this.validSend = true;
    } catch (error) {
      console.log(error);
    }

    this.$store.commit("setLoading", false);
    this.sending = false;
    this.clearForm();
  }

  validateMail() {
    /* Set all elements to "touched"
        Trigger display of validation messages  */
    this.$v.$touch();

    if (!this.$v.$invalid) {
      this.sendPasswordMail();
    }
  }

  get mailErrors() {
    const errors: any[] = [];
    if (!this.$v.form.mail!.$dirty) return errors;
    !this.$v.form.mail!.email &&
      errors.push("Email needs to be a valid Email address!");
    !this.$v.form.mail!.required && errors.push("Email is required!");
    return errors;
  }
}
