import { RootState } from "@/types/RootState"
import { ImportSplit, VariableSheet } from "@/types/Sheets"
import { collection, deleteDoc, doc, getDoc, getDocs, query, updateDoc, where } from "firebase/firestore"
import { ActionContext } from "vuex"

export type Team = {
    id: string, name: string, membershipId: string, members: Array<string>, membership?: { members: Array<{ mail: string, id: string }> }
}

type TeamsModuleState = {
    teams: Array<Team>,
    isAdmin: boolean
}

const teamsModule = {
    state: (): TeamsModuleState => ({
        teams: [],
        isAdmin: false
    }),
    getters: {
        isAdmin: (state: TeamsModuleState) => {
            return state.isAdmin
        },
        getTeams: (state: TeamsModuleState) => {
            return state.teams
        },
        getTeamPermissionsByTemplate: (state: TeamsModuleState) => (template: VariableSheet) => {
            return state.teams.map((team) => template.read.includes(`team:${team.id}`) ? {
                name: team.name,
                permission: true,
                id: team.id,
            } : {
                name: team.name,
                permission: false,
                id: team.id,
            });
        },
        getTeamPermissionsBySplit: (state: TeamsModuleState) => (split: ImportSplit) => {
            return state.teams.map((team) => split.read.includes(`team:${team.id}`) ? {
                name: team.name,
                permission: true,
                id: team.id,
            } : {
                name: team.name,
                permission: false,
                id: team.id,
            });
        },
    },
    actions: {
        async queryIsAdmin(context: ActionContext<TeamsModuleState, RootState>) {
            const db = context.rootState.firestore

            // Create a reference to the cities collection
            const teamsRef = collection(db, "admins");

            // Create a query against the collection.
            const q = query(teamsRef, where("uid", "==", context.getters.getUser.uid));
            const querySnapshot = await getDocs(q);

            querySnapshot.size == 1 ? context.commit("setIsAdmin", true) : context.commit("setIsAdmin", false)
        },
        async deleteTeam(context: ActionContext<TeamsModuleState, RootState>, payload: Team) {
            const db = context.rootState.firestore

            const teamsRef = doc(db, "teams", payload.id);
            const membershipRef = doc(db, "memberships", payload.membershipId);
            await deleteDoc(teamsRef);
            await deleteDoc(membershipRef)
            context.commit("removeTeam", payload)
        },
        async createTeam(context: ActionContext<TeamsModuleState, RootState>, payload: { name: string }) {
            const membershipObj = { members: [{ email: context.getters.getUser.email, id: context.getters.getUser.uid }] }
            const newMembership = await context.dispatch("createDocument", { collection: "memberships", dataObj: membershipObj })
            const teamObj = { members: [context.getters.getUser.uid], membershipId: newMembership.id, membership: membershipObj, ...payload }
            const newTeam = await context.dispatch(
                "createDocument",
                {
                    collection: "teams",
                    dataObj: teamObj,
                }
            );

            context.commit("addTeam", { id: newTeam.id, ...teamObj });
        },
        async queryTeams(context: ActionContext<TeamsModuleState, RootState>) {
            const db = context.rootState.firestore
            const currentUser = context.getters.getUser

            if (currentUser) {

                // Create a reference to the cities collection
                const teamsRef = collection(db, "teams");

                // Create a query against the collection.
                const teamQuery = query(teamsRef, where("members", "array-contains", currentUser.uid));
                const teamQuerySnapshot = await getDocs(teamQuery);

                const teamsArr = await Promise.all(teamQuerySnapshot.docs.flatMap(async (team) => {
                    const membershipRef = doc(db, "memberships", team.data().membershipId);
                    const membershipQuerySnapshot = await getDoc(membershipRef);

                    return { id: team.id, ...team.data(), membership: membershipQuerySnapshot.data() }
                }))

                context.commit("addTeams", teamsArr)
            }
        },
        async addUserToTeam(context: ActionContext<TeamsModuleState, RootState>, payload: { mail: string, team: Team }) {
            return context.dispatch("sendInviteMail", payload)
        },
        async deleteUserFromTeam(context: ActionContext<TeamsModuleState, RootState>, payload: { id: string, team: Team }) {
            const db = context.rootState.firestore

            const teamRef = doc(db, "teams", payload.team.id);
            const newTeam = { members: payload.team.members.filter((m) => m !== payload.id), membership: { members: payload.team.membership?.members.filter((m) => m.id !== payload.id) } }

            const membershipRef = doc(db, "memberships", payload.team.membershipId)
            const newMembership = { members: payload.team.membership?.members.filter((m) => m.id !== payload.id) }

            await updateDoc(membershipRef, newMembership)
            await updateDoc(teamRef, newTeam);

            context.commit("removeUserFromTeam", payload)
        },
    },
    mutations: {
        addTeams(state: TeamsModuleState, payload: Array<Team>) {
            state.teams.push(...payload)
        },
        addTeam(state: TeamsModuleState, payload: Team) {
            state.teams.push(payload)
        },
        clearTeams(state: TeamsModuleState) {
            state.teams = []
        },
        setIsAdmin(state: TeamsModuleState, isAdmin: boolean) {
            state.isAdmin = isAdmin
        },
        removeTeam(state: TeamsModuleState, payload: Team) {
            state.teams = state.teams.filter((team) => team.id !== payload.id)
        },
        removeUserFromTeam(state: TeamsModuleState, payload: { id: string, team: Team }) {
            state.teams = state.teams.map(
                (team) => {
                    if (team.id === payload.team.id)
                        return {
                            ...team, members: team.members.filter((m) => m !== payload.id), membership: { members: team.membership!.members.filter((m) => m.id !== payload.id) }
                        }
                    else return team
                }
            );
        },
    },
}


export { teamsModule }