<template>
  <div>
    <v-row justify="center">
      <v-col xl="4" md="6" sm="10">
        <v-card class="py-2 px-2">
          <v-card-title class="justify-center pt-12">
            <h3>Error 404: Not Found</h3>
          </v-card-title>
          <v-row justify="center" class="py-4">
            <v-icon x-large>error</v-icon>
          </v-row>
          <v-row justify="center">
            <span class="text-subtitle-2">This site does not exist.</span>
          </v-row>
          <v-card-actions class="pt-12">
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {};
</script>

<style>
.red-border {
  border-color: #f44336;
}
</style>
