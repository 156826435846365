import { Note } from "@/types/Notes";
import { RootState } from "@/types/RootState";
import { collection, query, where, getDocs, doc, updateDoc, deleteDoc } from "firebase/firestore";
import { ActionContext } from "vuex";

type NoteModuleState = {
    notes?: Array<Note>,
    feedback: Array<Note>
}

const notesModule = {
    state: (): NoteModuleState => ({
        notes: [],
        feedback: []
    }),
    getters: {
        getFeedback: (state: NoteModuleState) => {
            return state.feedback
        },
        getFeedbackForUser: (state: NoteModuleState) => (
            userId: string,
        ) => {
            return state.feedback.filter((feedback) => feedback.write.includes(userId))
        },
        getNotes: (state: NoteModuleState) => {
            return state.notes
        },
    },
    actions: {
        async setNoteState(
            context: ActionContext<NoteModuleState, RootState>,
        ) {
            const db = context.rootState.firestore
            const uid = context.getters.getUser.uid

            const notesRef = collection(db, "notes");
            const notesQuery = query(notesRef, where("write", "array-contains", uid));
            const notesQuerySnapshot = await getDocs(notesQuery);
            const notes = notesQuerySnapshot.docs.flatMap((note) => { return { id: note.id, ...note.data() } })
            context.commit("setNotes", notes)

            if (!context.getters.isAdmin) {
                const feedbackRef = collection(db, "feedback")
                const feedbackQuery = query(feedbackRef, where("read", "array-contains", uid));
                const feedbackQuerySnapshot = await getDocs(feedbackQuery);
                const feedbackArr = feedbackQuerySnapshot.docs.flatMap((feedback) => { return { id: feedback.id, ...feedback.data() } })
                context.commit("addFeedbackArr", feedbackArr)
            }
        },
        async deleteNote(context: ActionContext<Note, RootState>, payload: Note) {
            const db = context.rootState.firestore

            const noteRef = doc(db, "notes", payload.id);
            await deleteDoc(noteRef);
            context.commit("removeNote", payload)
        },
        async persistNote(context: ActionContext<Note, RootState>, payload: Note) {
            const db = context.rootState.firestore

            const noteRef = doc(db, "notes", payload.id);
            await updateDoc(noteRef, payload);
            context.commit("updateNote", payload)
        },
        async setFeedbackState(
            context: ActionContext<NoteModuleState, RootState>,
        ) {
            const db = context.rootState.firestore
            const uid = context.getters.getUser.uid

            const feedbackRef = collection(db, "feedback")
            const feedbackQuery = query(feedbackRef, where("write", "array-contains", uid));
            const feedbackQuerySnapshot = await getDocs(feedbackQuery);
            const feedbackArr = feedbackQuerySnapshot.docs.flatMap((feedback) => { return { id: feedback.id, ...feedback.data() } })
            context.commit("addFeedbackArr", feedbackArr)
        },
        async deleteFeedback(context: ActionContext<Note, RootState>, payload: Note) {
            const db = context.rootState.firestore

            const feedbackRef = doc(db, "feedback", payload.id);
            await deleteDoc(feedbackRef);
            context.commit("removeFeedback", payload)
        },
        async persistFeedback(context: ActionContext<Note, RootState>, payload: Note) {
            const db = context.rootState.firestore

            const feedbackRef = doc(db, "feedback", payload.id);
            await updateDoc(feedbackRef, payload);
            context.commit("updateFeedback", payload)
        },
    },
    mutations: {
        setNotes(state: NoteModuleState, notes: Array<Note>) {
            state.notes = notes
        },
        addNote(state: NoteModuleState, note: Note) {
            state.notes?.push(note)
        },
        updateNote(state: NoteModuleState, payload: Note) {
            state.notes = state.notes?.map((note) => {
                if (note.id === payload.id) return payload
                else return note
            })
        },
        removeNote(state: NoteModuleState, payload: Note) {
            state.notes = state.notes?.filter(
                (note) => note.id !== payload.id
            );
        },
        addFeedbackArr(state: NoteModuleState, feedback: Array<Note>) {
            state.feedback.push(...feedback)
        },
        addFeedback(state: NoteModuleState, feedback: Note) {
            state.feedback.push(feedback)
        },
        updateFeedback(state: NoteModuleState, payload: Note) {
            state.feedback = state.feedback?.map((feedback) => {
                if (feedback.id === payload.id) return payload
                else return feedback
            })
        },
        removeFeedback(state: NoteModuleState, payload: Note) {
            state.feedback = state.feedback?.filter(
                (feedback) => feedback.id !== payload.id
            );
        },
    },
}

export { notesModule }