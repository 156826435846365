























































































































// eslint-disable-next-line no-unused-vars
import { VariableSheet } from "@/types/Sheets";
import { Component, Prop, Vue } from "vue-property-decorator";
import { validationMixin } from "vuelidate";
import { required, decimal } from "vuelidate/lib/validators";

@Component({
  mixins: [validationMixin],
  validations: {
    form: {
      columnKey: {
        required,
      },
      innerBottomThreshold: {
        required,
        decimal,
      },
      innerTopThreshold: {
        required,
        decimal,
      },
      slightTopThreshold: {
        required,
        decimal,
      },
      slightBottomThreshold: {
        required,
        decimal,
      },
      mediumTopThreshold: {
        required,
        decimal,
      },
      mediumBottomThreshold: {
        required,
        decimal,
      },
    },
  },
})
export default class CreateMacroColumns extends Vue {
  @Prop() template!: VariableSheet;
  @Prop() categories!: string[];

  form = {
    columnKey: null,
    innerBottomThreshold: null,
    innerTopThreshold: null,
    slightBottomThreshold: null,
    slightTopThreshold: null,
    mediumBottomThreshold: null,
    mediumTopThreshold: null,
  };

  get columnKeyErrors() {
    const errors: any[] = [];
    if (!this.$v.form.columnKey!.$dirty) return errors;
    !this.$v.form.columnKey!.required && errors.push("Column Key is required!");
    return errors;
  }

  get innerBottomThresholdErrors() {
    const errors: any[] = [];
    if (!this.$v.form.innerBottomThreshold!.$dirty) return errors;
    !this.$v.form.innerBottomThreshold!.decimal &&
      errors.push("Inner Bottom Threshold needs to be a number!");
    !this.$v.form.innerBottomThreshold!.required &&
      errors.push("Inner Bottom Threshold is required!");
    return errors;
  }

  get innerTopThresholdErrors() {
    const errors: any[] = [];
    if (!this.$v.form.innerTopThreshold!.$dirty) return errors;
    !this.$v.form.innerTopThreshold!.decimal &&
      errors.push("Inner Top Threshold needs to be a number!");
    !this.$v.form.innerTopThreshold!.required &&
      errors.push("Inner Top Threshold is required!");
    return errors;
  }

  get slightBottomThresholdErrors() {
    const errors: any[] = [];
    if (!this.$v.form.slightBottomThreshold!.$dirty) return errors;
    !this.$v.form.slightBottomThreshold!.decimal &&
      errors.push("Slight Bottom Threshold needs to be a number!");
    !this.$v.form.slightBottomThreshold!.required &&
      errors.push("Slight Bottom Threshold is required!");
    return errors;
  }

  get slightTopThresholdErrors() {
    const errors: any[] = [];
    if (!this.$v.form.slightTopThreshold!.$dirty) return errors;
    !this.$v.form.slightTopThreshold!.decimal &&
      errors.push("Slight Top Threshold needs to be a number!");
    !this.$v.form.slightTopThreshold!.required &&
      errors.push("Slight Top Threshold is required!");
    return errors;
  }

  get mediumBottomThresholdErrors() {
    const errors: any[] = [];
    if (!this.$v.form.mediumBottomThreshold!.$dirty) return errors;
    !this.$v.form.mediumBottomThreshold!.decimal &&
      errors.push("Medium Bottom Threshold needs to be a number!");
    !this.$v.form.mediumBottomThreshold!.required &&
      errors.push("Medium Bottom Threshold is required!");
    return errors;
  }

  get mediumTopThresholdErrors() {
    const errors: any[] = [];
    if (!this.$v.form.mediumTopThreshold!.$dirty) return errors;
    !this.$v.form.mediumTopThreshold!.decimal &&
      errors.push("Medium Top Threshold needs to be a number!");
    !this.$v.form.mediumTopThreshold!.required &&
      errors.push("Medium Top Threshold is required!");
    return errors;
  }

  validateCreateColumn() {
    /* Set all elements to "touched"
        Trigger display of validation messages  */
    this.$v.$touch();

    if (!this.$v.$invalid) {
      this.createColumn();
      this.clearForm();
    }
  }

  async createColumn() {
    this.$store.commit("setLoading", true);

    let newCat = this.form.columnKey!;
    let updatedTemplate: VariableSheet = this.template;

    /* Fill up rows with new column category */
    updatedTemplate.rows.forEach((rowObj: any) => {
      this.$set(rowObj, newCat, "");
    });

    updatedTemplate.thresholds.push({
      category: newCat,
      inner_top: this.form.innerTopThreshold!,
      inner_bottom: this.form.innerBottomThreshold!,
      slight_top: this.form.slightTopThreshold!,
      slight_bottom: this.form.slightBottomThreshold!,
      medium_top: this.form.mediumTopThreshold!,
      medium_bottom: this.form.mediumBottomThreshold!,
    });

    await this.$store.dispatch("persistTemplate", updatedTemplate);
    this.$store.commit("setLoading", false);
  }

  clearForm() {
    this.$v.$reset();
    this.form.columnKey = null;
    this.form.innerTopThreshold = null;
    this.form.innerBottomThreshold = null;
    this.form.slightTopThreshold = null;
    this.form.slightBottomThreshold = null;
    this.form.mediumBottomThreshold = null;
    this.form.mediumTopThreshold = null;
  }
}
