import { RootState } from "@/types/RootState";
import { VariableSheet } from "@/types/Sheets"
import { getApp } from "firebase/app";
import { collection, doc, updateDoc } from "firebase/firestore";
import { query, where, getDocs, addDoc, getDocsFromServer } from "firebase/firestore";
import { connectFunctionsEmulator, getFunctions, httpsCallable } from "firebase/functions";
import { ActionContext } from "vuex";
import { Team, teamsModule } from "./teams";

type FunctionModuleState = {
}

const functionsModule = {
    state: (): FunctionModuleState => ({
    }),
    getters: {
    },
    actions: {
        async sendInviteMail(context: ActionContext<FunctionModuleState, RootState>, payload: { mail: string, team: Team }) {
            const functions = getFunctions(getApp())
            /* connectFunctionsEmulator(functions, "localhost", 5001); */
            const sendInvite = httpsCallable(functions, 'sendInvite');
            return sendInvite({ email: payload.mail, membershipId: payload.team.membershipId, teamId: payload.team.id })
        },
    },
}

export { functionsModule }