
















import Vue from "vue";
import Component from "vue-class-component";

@Component({})
export default class ChangePassword extends Vue {
  async validateMail() {
    this.$store.commit("setLoading", true);

    await this.$store.dispatch(
      "createPasswordReset",
      this.$store.getters.getUser.email
    );
    this.$store.commit("setLoading", false);
  }
}
