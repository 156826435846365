












import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import CreateMacroColumns from "@/components/Templates/ContextMenu/Dialog/Macro/Columns/CreateMacroColumns.vue";
// eslint-disable-next-line no-unused-vars
import { VariableSheet } from "@/types/Sheets";

@Component({
  components: {
    CreateMacroColumns,
  },
})
export default class MacroColumns extends Vue {
  @Prop() template!: VariableSheet;

  get categories(): string[] {
    let arr: string[] = [];

    this.template.thresholds.forEach((thresholdObj: any) =>
      arr.push(thresholdObj.category!)
    );

    return arr;
  }

  async deleteColumnChip(category: string) {
    let updatedTemplate: VariableSheet = this.template;

    /* Filter thresholds for category and return only thresholds that don't match category */
    updatedTemplate.thresholds = updatedTemplate.thresholds.filter(
      (thresholdObj: any) => thresholdObj.category !== category
    );

    /* Delete Key Value Pairs from rows */
    updatedTemplate.rows.forEach((rowObj: any) => {
      delete rowObj[category];
    });
    this.$store.commit("setLoading", true);

    await this.$store.dispatch("persistTemplate", updatedTemplate);
    this.$store.commit("setLoading", false);
  }
}
