
















































































































































































































import Vue from "vue";
import MacroSheet from "@/components/Shared/RangeSheets/MacroSheet.vue";
import MicroSheet from "@/components/Shared/RangeSheets/MicroSheet.vue";
import SplitDisplay from "@/components/Shared/SplitDisplay.vue";
import Component from "vue-class-component";
import { ImportSplit } from "@/types/Sheets";
import CSVImportDialog from "./CSVImportDialog.vue";

@Component({
  components: { MacroSheet, MicroSheet, SplitDisplay, CSVImportDialog },
})
export default class SheetsPanel extends Vue {
  showDeleteDialog: boolean = false;
  showCSVDialog = false;
  showSplitDisplay: boolean = false;

  get sheet() {
    return this.$store.getters.getSheet(this.$route.params.id);
  }

  get relatedSplit(): ImportSplit | null {
    return this.sheet
      ? this.$store.getters.getSplitById(this.sheet.split)
      : null;
  }

  async deleteSheet() {
    this.$store.commit("setLoading", true);

    try {
      await this.$store.dispatch("deleteSheetsSplit", this.sheet.split);
      this.$router.push({ name: "Sheets" });
      this.$store.commit("setLoading", false);
    } catch (error) {
      process.env.NODE_ENV == "development" && console.log(error);
      this.$store.commit("setLoading", false);
    }
  }

  async saveSheet() {
    this.$store.commit("setLoading", true);
    try {
      await this.$store.dispatch("persistSheet", this.sheet);
    } catch (error) {
      process.env.NODE_ENV == "development" && console.log(error);
    }
    this.$store.commit("setLoading", false);
  }

  async persistMicroSheet(event: any) {
    this.$store.commit("setLoading", true);
    try {
      console.log(event);
      const updatedSheets = this.sheet.sheets.map(
        (singleSheet: any, index: any) =>
          index != event.index ? singleSheet : { ...event, index: null }
      );

      await this.$store.dispatch("persistSheet", {
        ...this.sheet,
        sheets: updatedSheets,
      });
    } catch (error) {
      process.env.NODE_ENV == "development" && console.log(error);
    }
    this.$store.commit("setLoading", false);
  }
  async addMicroSheet() {
    this.$store.commit("setLoading", true);
    const lastSheet = this.sheet.sheets[this.sheet.sheets.length - 1];
    await this.$store.dispatch("persistSheet", {
      ...this.sheet,
      sheets: [
        ...this.sheet.sheets,
        { ...lastSheet, label: `Sheet #${this.sheet.sheets.length + 1}` },
      ],
    });
    this.$store.commit("setLoading", false);
  }
  async removeMicroSheet(event: any) {
    this.$store.commit("setLoading", true);
    await this.$store.dispatch("persistSheet", {
      ...this.sheet,
      sheets: this.sheet.sheets.filter(
        (sheet: any, index: any) => index != event
      ),
    });
    this.$store.commit("setLoading", false);
  }
}
