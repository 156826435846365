

























































import Vue from "vue";
import Component from "vue-class-component";
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";

@Component({
  mixins: [validationMixin],
  validations: {
    form: {
      mail: {
        email,
        required,
      },
    },
  },
})
export default class DeleteAccount extends Vue {
  form = {
    mail: "",
  };

  showDeleteAccountDialog = false;

  get inputMailEqualsAccountMail() {
    return this.form.mail === this.$store.getters.getUser.email;
  }

  get mailErrors() {
    const errors: any[] = [];
    if (!this.$v.form.mail!.$dirty) return errors;
    !this.$v.form.mail!.required && errors.push("Your Email is required!");
    !this.$v.form.mail!.email &&
      errors.push("Your input has to be a valid Email!");
    return errors;
  }

  async deleteAccount() {
    this.$store.commit("setLoading", true);

    this.$router.push({ name: "Landing" });
    await this.$store.dispatch("deleteAccount");
    this.$store.commit("setLoading", false);
  }
}
