import { RootState } from "@/types/RootState";
import Vue from "vue";
import Vuex from "vuex";
import { FirebaseOptions, initializeApp } from "firebase/app";
import { getFirestore, initializeFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";
import { templatesModule } from "./modules/templates";
import { graphsModule } from "./modules/graphs";
import { notesModule } from "./modules/notes";
import { sheetsModule } from "./modules/sheets";
import { sessionModule } from "./modules/session";
import { teamsModule } from "./modules/teams";
import { documentsModule } from "./modules/documents";
import { filesModule } from "./modules/files";
import { getFunctions } from 'firebase/functions';
import { functionsModule } from "./modules/functions";
import { populationModule } from "./modules/population";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig: FirebaseOptions = {
  apiKey: "AIzaSyBigKq_RpyepP0i_DUjfnz3hDaWOl85J5Q",
  authDomain: "mttstats-5a9b2.firebaseapp.com",
  projectId: "mttstats-5a9b2",
  storageBucket: "mttstats-5a9b2.appspot.com",
  messagingSenderId: "1046752044170",
  appId: "1:1046752044170:web:c0df9df681490a763ab973",
  measurementId: "G-D0HTRLXMG9",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const firestore = initializeFirestore(app, { experimentalAutoDetectLongPolling: true })
const storage = getStorage(app)
const functions = getFunctions(app)

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    documents: documentsModule,
    files: filesModule,
    templates: templatesModule,
    sheets: sheetsModule,
    grahps: graphsModule,
    notes: notesModule,
    session: sessionModule,
    teams: teamsModule,
    functions: functionsModule,
    population: populationModule,
  },
  getters: {
    getLoading: (state: RootState) => {
      return state.loading
    },
  },
  mutations: {
    setLoading(state: RootState, payload: boolean) {
      state.loading = payload;
    },
  },
  state: new RootState({ firebaseApp: app, firebaseAnalytics: analytics, firebaseFirestore: firestore, firebaseStorage: storage, firebaseFunctions: functions }),
});