<template>
  <admin-panel></admin-panel>
</template>

<script>
import Vue from "vue";
import Component from "vue-class-component";
import AdminPanel from "@/components/Admin/AdminPanel.vue";

@Component({
  components: {
    AdminPanel,
  },
})
export default class Admin extends Vue {}
</script>

<style>
</style>