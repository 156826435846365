var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var toggle = ref.toggle;
return [_c('v-card',{staticClass:"d-flex align-center justify-center white--text",staticStyle:{"text-decoration":"none"},style:(_vm.groupItem.split && !_vm.isActive(_vm.groupItem)
        ? ("" + ('border-right: 10px solid' +
            _vm.colorCode(_vm.groupItem.split) +
            ';border-bottom: 10px solid' +
            _vm.colorCode(_vm.groupItem.split) +
            ';text-decoration:none'))
        : 'text-decoration:none'),attrs:{"outlined":"","color":_vm.isActive(_vm.groupItem),"dark":"","height":"80","to":_vm.to(_vm.groupItem)},on:{"click":toggle}},[_c('span',{staticClass:"text-truncate px-2 text-caption"},[_vm._v(" "+_vm._s(_vm.groupItem.label)+" ")])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }