






import { Component, Vue } from "vue-property-decorator";
import LoginComponent from "@/components/Login/LoginComponent.vue";

@Component({
  components: {
    LoginComponent
  },
  name: "Login"
})
export default class Login extends Vue {}
