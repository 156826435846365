import Landing from "@/components/views/Landing.vue";
import Login from "@/components/views/Login.vue";
import Sheets from "@/components/views/Sheets.vue";
import Templates from "@/components/views/Templates.vue";
import Admin from "@/components/views/Admin.vue";
import Graphs from "@/components/views/Graphs.vue";
import NotFoundComponent from "@/components/views/NotFound.vue";
import ForgotPassword from "@/components/views/ForgotPassword.vue";
import SheetsPanel from "@/components/Sheets/SheetsPanel.vue";
import Settings from "@/components/views/Settings.vue"
import TemplatesPanel from "@/components/Templates/TemplatesPanel.vue";
import Notes from "@/components/views/Notes.vue";
import Imprint from "@/components/views/Imprint.vue"

const routes = [
    { path: "*", component: NotFoundComponent, name: "Not Found" },
    { path: "/", name: "Root", redirect: { name: "Landing" } },
    { path: "/home", component: Landing, name: "Landing" },
    { path: "/imprint", component: Imprint, name: "Imprint" },
    { path: "/admin", component: Admin, name: "Admin" },
    {
        path: "/sheets",
        component: Sheets,
        name: "Sheets",
        children: [{ path: ":id", component: SheetsPanel }],
    },
    {
        path: "/settings",
        component: Settings,
        name: "Settings",
    },
    {
        path: "/templates",
        component: Templates,
        name: "Templates",
        children: [{ path: ":id", component: TemplatesPanel }],
    },
    {
        path: "/login",
        component: Login,
        name: "Login",
    },
    { path: "/login/forgot", name: "ForgotPassword", component: ForgotPassword },
    {
        path: "/gallery",
        component: Graphs,
        name: "Graphs",
    },
    {
        path: "/notes",
        component: Notes,
        name: "Notes",
    },
];

export default routes