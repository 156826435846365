






































































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { required, decimal } from "vuelidate/lib/validators";
import sheetColors from "@/store/util/sheetColors";
import { validationMixin } from "vuelidate";
// eslint-disable-next-line no-unused-vars
import { VariableSheet } from "@/types/Sheets";

@Component({
  mixins: [validationMixin],
  validations: {
    form: {
      excludeCell: {},
      flipColors: {},
      noColors: {},
      comment: {},
      innerBottomThreshold: {
        required,
        decimal,
      },
      innerTopThreshold: {
        required,
        decimal,
      },
      slightTopThreshold: {
        required,
        decimal,
      },
      slightBottomThreshold: {
        required,
        decimal,
      },
      mediumTopThreshold: {
        required,
        decimal,
      },
      mediumBottomThreshold: {
        required,
        decimal,
      },
    },
  },
})
export default class EditMicroThresholds extends Vue {
  @Prop() template!: VariableSheet;
  @Prop() category!: string;
  @Prop() position!: string;
  sheetColors = sheetColors;

  get usedThreshold() {
    const thresholdForPosition = this.template?.thresholds.find(
      (t) => t.position === this.position
    );

    const thresholdForCategory = thresholdForPosition?.thresholds?.find(
      (t) => t.category === this.category
    );
    return thresholdForCategory;
  }

  // When Sheet changes (another Sheet is selected), refresh the form data
  @Watch("template")
  onTemplateChanged() {
    const newForm = {
      innerBottomThreshold: this.usedThreshold?.inner_bottom,
      innerTopThreshold: this.usedThreshold?.inner_top,
      slightBottomThreshold: this.usedThreshold?.slight_bottom,
      slightTopThreshold: this.usedThreshold?.slight_top,
      mediumBottomThreshold: this.usedThreshold?.medium_bottom,
      mediumTopThreshold: this.usedThreshold?.medium_top,
      excludeCell: this.usedThreshold?.excludeCell,
      flipColors: this.usedThreshold?.flipColors,
      noColors: this.usedThreshold?.noColors,
      comment: this.usedThreshold?.comment ? this.usedThreshold?.comment : "",
    };

    this.form = { ...newForm };
  }

  created() {
    this.form = {
      innerBottomThreshold: this.usedThreshold?.inner_bottom,
      innerTopThreshold: this.usedThreshold?.inner_top,
      slightBottomThreshold: this.usedThreshold?.slight_bottom,
      slightTopThreshold: this.usedThreshold?.slight_top,
      mediumBottomThreshold: this.usedThreshold?.medium_bottom,
      mediumTopThreshold: this.usedThreshold?.medium_top,
      excludeCell: this.usedThreshold?.excludeCell,
      flipColors: this.usedThreshold?.flipColors,
      noColors: this.usedThreshold?.noColors,
      comment: this.usedThreshold?.comment ? this.usedThreshold?.comment : "",
    };
  }

  form = {
    innerBottomThreshold: this.usedThreshold?.inner_bottom,
    innerTopThreshold: this.usedThreshold?.inner_top,
    slightBottomThreshold: this.usedThreshold?.slight_bottom,
    slightTopThreshold: this.usedThreshold?.slight_top,
    mediumBottomThreshold: this.usedThreshold?.medium_bottom,
    mediumTopThreshold: this.usedThreshold?.medium_top,
    excludeCell: this.usedThreshold?.excludeCell,
    flipColors: this.usedThreshold?.flipColors,
    noColors: this.usedThreshold?.noColors,
    comment: "",
  };

  validateSetDeviation() {
    /* Set all elements to "touched"
        Trigger display of validation messages  */
    this.$v.form.$touch();

    if (!this.$v.$invalid) {
      this.changeDeviation();
    }
  }

  async changeDeviation() {
    this.$store.commit("setLoading", true);

    const updatedThresholds = this.template.thresholds.map((t: any) => {
      if (t.position === this.position) {
        return {
          ...t,
          thresholds: t.thresholds.map((innerT: any) => {
            if (innerT.category === this.category) {
              return {
                ...innerT,
                inner_top: this.form.innerTopThreshold,
                inner_bottom: this.form.innerBottomThreshold,
                slight_top: this.form.slightTopThreshold,
                slight_bottom: this.form.slightBottomThreshold,
                medium_top: this.form.mediumTopThreshold,
                medium_bottom: this.form.mediumBottomThreshold,
                excludeCell: Boolean(this.form.excludeCell),
                flipColors: Boolean(this.form.flipColors),
                noColors: Boolean(this.form.noColors),
                comment: this.form.comment,
              };
            } else return innerT;
          }),
        };
      } else return t;
    });

    try {
      await this.$store.dispatch("persistTemplate", {
        ...this.template,
        thresholds: updatedThresholds,
      });
      this.$store.commit("setLoading", false);
    } catch (error) {
      this.$store.commit("setLoading", false);

      console.log(error);
    }
  }

  get mediumTopThresholdErrors() {
    const errors: any[] = [];
    if (!this.$v.form.mediumTopThreshold!.$dirty) return errors;
    !this.$v.form.mediumTopThreshold!.decimal &&
      errors.push("Medium Top Threshold needs to be a number!");
    !this.$v.form.mediumTopThreshold!.required &&
      errors.push("Medium Top Threshold is required!");
    return errors;
  }

  get mediumBottomThresholdErrors() {
    const errors: any[] = [];
    if (!this.$v.form.mediumBottomThreshold!.$dirty) return errors;
    !this.$v.form.mediumBottomThreshold!.decimal &&
      errors.push("Medium Bottom Threshold needs to be a number!");
    !this.$v.form.mediumBottomThreshold!.required &&
      errors.push("Medium Bottom Threshold is required!");
    return errors;
  }

  get slightTopThresholdErrors() {
    const errors: any[] = [];
    if (!this.$v.form.slightTopThreshold!.$dirty) return errors;
    !this.$v.form.slightTopThreshold!.decimal &&
      errors.push("Slight Top Threshold needs to be a number!");
    !this.$v.form.slightTopThreshold!.required &&
      errors.push("Slight Top Threshold is required!");
    return errors;
  }

  get slightBottomThresholdErrors() {
    const errors: any[] = [];
    if (!this.$v.form.slightBottomThreshold!.$dirty) return errors;
    !this.$v.form.slightBottomThreshold!.decimal &&
      errors.push("Slight Bottom Threshold needs to be a number!");
    !this.$v.form.slightBottomThreshold!.required &&
      errors.push("Slight Bottom Threshold is required!");
    return errors;
  }

  get innerBottomThresholdErrors() {
    const errors: any[] = [];
    if (!this.$v.form.innerBottomThreshold!.$dirty) return errors;
    !this.$v.form.innerBottomThreshold!.decimal &&
      errors.push("Inner Bottom Threshold needs to be a number!");
    !this.$v.form.innerBottomThreshold!.required &&
      errors.push("Inner Bottom Threshold is required!");
    return errors;
  }

  get innerTopThresholdErrors() {
    const errors: any[] = [];
    if (!this.$v.form.innerTopThreshold!.$dirty) return errors;
    !this.$v.form.innerTopThreshold!.decimal &&
      errors.push("Inner Top Threshold needs to be a number!");
    !this.$v.form.innerTopThreshold!.required &&
      errors.push("Inner Top Threshold is required!");
    return errors;
  }
}
