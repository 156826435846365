import { Graph } from "@/types/Graphs";
import { RootState } from "@/types/RootState";
import { PositionalSheet, PreflopSheet, VariableSheet } from "@/types/Sheets";
import { collection, doc, getDocs, orderBy, query, updateDoc, where } from "firebase/firestore";
import { deleteObject, getDownloadURL, ref, uploadBytes, UploadMetadata } from "firebase/storage";
import { sortBy } from "lodash-es";
import { ActionContext } from "vuex";

type PopulationModuleState = {
    preflopPopulation: PreflopSheet | null;
    positionalPopulation: PositionalSheet | null;
}

function splitObject(data: any, n: number) {
    const result = [];
    const thresholdsLength = data.thresholds[0].thresholds.length;
    const thresholdsPerPart = Math.ceil(thresholdsLength / n);

    for (let i = 0; i < n; i++) {
        const startThresholdIndex = i * thresholdsPerPart;
        const endThresholdIndex = Math.min(startThresholdIndex + thresholdsPerPart, thresholdsLength);

        // Splitting thresholds
        const splitThresholds = data.thresholds.map((thresholdGroup: { position: any; thresholds: string | any[]; }) => {
            return {
                position: thresholdGroup.position,
                thresholds: thresholdGroup.thresholds.slice(startThresholdIndex, endThresholdIndex)
            };
        });

        // Copying rows and other properties
        result.push({
            rows: [...data.rows],
            thresholds: splitThresholds,
            label: data.label,
            read: [...data.read],
            sheets: [...data.sheets],
            type: data.type,
            write: [...data.write],
            uploadedAt: data.uploadedAt
        });
    }

    return result;
}

const populationModule = {
    state: (): PopulationModuleState => ({
        preflopPopulation: null,
        positionalPopulation: null,
    }),
    getters: {
        getPreflopPopulation: (state: PopulationModuleState) => {
            console.log(state.preflopPopulation)
            /* const oneThird = {
                ...state.preflopPopulation,
                rows: state.preflopPopulation?.rows.map((row: any) => {
                    const keys = Object.keys(row)
                    const positionalValue = row["position"] as string
                    // remove "position" key
                    const filteredKeys = keys.filter((key) => key !== "position")
                    const oneThird = Math.floor(filteredKeys.length / 3)
                    const slicedKeys = filteredKeys.slice(0, oneThird)
                    const newRow = {
                        position: row.position,
                    }),
                } */
            return splitObject(state.preflopPopulation, 5)
        },
        getPositionalPopulation: (state: PopulationModuleState) => {
            return state.positionalPopulation
        }
    },
    actions: {
        async setPopulationState(
            context: ActionContext<PopulationModuleState, RootState>,
        ) {
            const db = context.rootState.firestore

            const popRef = collection(db, "population");
            const preflopPopQuery = query(popRef, where("type", "==", "preflop"), orderBy("uploadedAt", "desc"));
            const positionalPopQuery = query(popRef, where("type", "==", "positional"), orderBy("uploadedAt", "desc"));

            const preflopPops = await getDocs(preflopPopQuery)
            const positionalPops = await getDocs(positionalPopQuery)

            const preflopPop = preflopPops.docs[0]?.data() as PreflopSheet
            const positionalPop = positionalPops.docs[0]?.data() as PositionalSheet

            context.commit("setPreflopPopulation", preflopPop)
            context.commit("setPositionalPopulation", positionalPop)
        },
    },
    mutations: {
        setPreflopPopulation(state: PopulationModuleState, payload: PreflopSheet) {
            state.preflopPopulation = payload
        },
        setPositionalPopulation(state: PopulationModuleState, payload: PositionalSheet) {
            state.positionalPopulation = payload
        },
    }
}

export { populationModule }